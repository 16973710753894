import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

export const useNetwork = () => {
  const dispatch = useDispatch();
  const { isDisconected } = useSelector((state) => {
    return {
      isDisconected: state.isDisconected,
    };
  });
  const updateNetwork = () => {
    console.log("here", isDisconected);
    dispatch({ type: "CONNECTION", payload: !isDisconected });
  };
  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  });
  return isDisconected;
};
