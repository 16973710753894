import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import ButtonGroup from "../ButtonGroup/ButtonGroup";

import Menu from "../Menu/Menu";
import Cart from "../Cart/Cart";
import BottomButtonGroup from "../ButtonGroup/BottomButton";
import HoldTransaction from "../HoldTransaction/HoldTransaction";
import { useDispatch, useSelector } from "react-redux";
import FinishTransaction from "../FinishTransaction/FinishTransaction";
import DisplayRecipt from "../FinishTransaction/DisplayRecipt";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TablesPopup from "../tableService/tables";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getBrandData } from "../../api/service";
import { Backdrop } from "@material-ui/core";

const MainPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { displayOption, UserId, token, store } = useSelector((state) => {
    return {
      displayOption: state.displayOption,
      UserId: state.UserId,
      token: state.token,
      store: state.store,
    };
  });

  const [number, setNumber] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNumber = (e) => {
    setNumber(e);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getBrandData(UserId, token)
        .then((res) => {
          if (res.Stores[0]) {
            let areas = res.Stores[0].Areas;
            let tableList = [];
            let id = 1;
            for (let i = 0; i < areas.length; i++) {
              for (let n = 0; n < areas[i].NumberOfTables; n++) {
                tableList.push({
                  id: id,
                  available: true,
                  areaName: areas[i].AreaName,
                  orderDetail: {},
                });
                id++;
              }
            }
            dispatch({
              type: "BRANDDATA",
              payload: {
                store: res.Stores[0],
                tables: tableList.slice(),
              },
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("Error in fetching brand data: " + error);
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  // //if (fullScreen) return <Compabilte />;

  return (
    <div style={{ overflow: "hidden" /* , marginTop: 10 */ }}>
      {isLoading ? (
        <Backdrop style={{ color: "#fff", zIndex: 2 }} open={true}>
          <CircularProgress color="secondary" />
        </Backdrop>
      ) : (
        ""
      )}
      <>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <ButtonGroup />
            {displayOption === "Menu" ? (
              <Menu handleNumber={handleNumber} />
            ) : displayOption === "FinishTransaction" ? (
              <FinishTransaction number={number} handleNumber={handleNumber} />
            ) : (
              <Menu />
            )}
            <Grid container spacing={4}>
              <Grid item xs={12} style={{ marginLeft: 8 }}>
                <HoldTransaction />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ heigth: "100%" }}>
            {displayOption === "Menu" ? (
              <Cart number={number} handleNumber={handleNumber} />
            ) : (
              <DisplayRecipt number={number} handleNumber={handleNumber} />
            )}
          </Grid>
        </Grid>
        <TablesPopup />
      </>
    </div>
  );
};

export default MainPage;
