/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
import React, { useEffect } from "react";
import { getOrderList } from "../../api/service";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Collapse,
  Box,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    maxHeight: 440,
  },
  raw: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const TableRowsData = (props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const date = new Date(
    props.data.Date ? props.data.Date : props.data.dateTime
  );

  return (
    <>
      <TableRow className={classes.raw}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{props.index + 1}</TableCell>
        <TableCell>
          {props.data.OrderId ? props.data.OrderId : props.data.TransID}
        </TableCell>
        <TableCell>{`${date.getHours()}:${date.getMinutes()}`}</TableCell>
        <TableCell>{`${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`}</TableCell>
        <TableCell>
          {"PKR" + " " + props.data.NetPayable
            ? props.data.NetPayable
            : props.data.netPayable}
          /-
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Order Items
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr. No</TableCell>
                    <TableCell>Item Name</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>Total Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.OrderItem
                    ? props.data.OrderItem.length > 0
                      ? props.data.OrderItem.map((order, i) => (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{order.Name}</TableCell>
                            <TableCell>{order.Count}</TableCell>
                            <TableCell>{order.ItemTotal}</TableCell>
                          </TableRow>
                        ))
                      : null
                    : props.data.orderItem.length > 0
                    ? props.data.orderItem.map((localOrder, i) => {
                        // console.log(localOrder)
                        return (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{localOrder.Name}</TableCell>
                            <TableCell>{localOrder.Count}</TableCell>
                            <TableCell>{localOrder.ItemTotal}</TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Xreport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loaclData, setLocalData] = React.useState(null);
  const { store, OrderList, token } = useSelector((state) => {
    return {
      store: state.store,
      OrderList: state.OrderList,
      token: state.token,
    };
  });
  useEffect(() => {
    const fetchData = async () => {
      const Orders = await getOrderList(store.StoreId, token);
      dispatch({ type: "ORDERS", payload: Orders });
      // console.log(Orders)
    };
    fetchData();
  }, []);
  useEffect(() => {
    let localTransaction = localStorage.getItem("transactions");
    if (localTransaction !== null) {
      localTransaction = JSON.parse(localTransaction);
      setLocalData(localTransaction);
    }
  }, []);
  OrderList.sort((a, b) => b.OrderId - a.OrderId);
  return (
    <>
      {loaclData ? (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>OrderId</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Total Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loaclData.length > 0
                  ? loaclData.map((row, i) => (
                      <TableRowsData data={row} index={i} key={i} />
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : null}
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Sr. No.</TableCell>
                <TableCell>OrderId</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Total Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {OrderList.length > 0
                ? OrderList.map((row, i) => (
                    <TableRowsData data={row} index={i} key={i} />
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default Xreport;
