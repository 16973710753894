import { Paper, Typography } from "@material-ui/core";

const KeyPadButton = (props) => {
  return (
    <Paper
      style={{ height: "100%", cursor: "pointer" }}
      onClick={() => props.onClick()}
    >
      <Typography style={{ fontSize: 17, fontWeight: "bold" }} component="p">
        {props.value}
      </Typography>
    </Paper>
  );
};

export default KeyPadButton;
