import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Paper,
  Typography,
  Container,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import { placeOrder } from "../../api/service";
import { useReactToPrint, onAfterPrint } from "react-to-print";
import ChangeDialoge from "./ChangeDialoge";
import { kotGenerator } from "../../utils/kotNumMaker";
import ReceiptFormat1 from "../printing/ReceiptFormat1";
import ReceiptFormat2 from "../printing/ReceiptFormat2";

const useStyles = makeStyles((theme) => ({
  printerpaper: {
    color: "black",
  },

  pageBreak: {
    "@media print": {
      display: "block",
      pageBreakAfter: "always",
    },
  },
}));

const HiddenReceipt = (props) => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: (data) => console.log("after print data: ", data),
  });

  const classes = useStyles();

  const { number, handleNumber } = props;
  const [deliveryFee, setDeliveryFee] = React.useState(0);
  const [taxAmount, setTaxAmount] = React.useState(0);

  const [total, setTotal] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [quantity, setQuantity] = React.useState(0);
  const [revenueAuthorityID, setRevenueAuthorityID] = React.useState("");
  const [isPrePaymentInvoice, setIsPrePaymentInvoice] = React.useState(false);

  const {
    store,
    menu,
    cart,
    FirstName,
    token,
    discount,
    adhocDiscountPer,
    discountPercent,
    selectedTableNumber,
  } = useSelector((state) => {
    return {
      store: state.store,
      menu: state.menu,
      cart: state.cartItems,
      FirstName: state.FirstName,
      token: state.token,
      discount: state.discount ?? 0,
      adhocDiscountPer: state.adhocDiscountPer ?? 0,
      discountPercent: state.discountPercent,
      selectedTableNumber: state.selectedTableId,
    };
  });

  React.useEffect(() => {
    let deliveryLocation = cart.customerInfo.DeliveryArea
      ? cart.customerInfo.DeliveryArea
      : "";
    if (cart.orderType === 0) {
      let charges = store.StoreDeliveryFees.filter(
        (data) => data.LocationName === deliveryLocation
      );
      if (charges.length > 0) {
        setDeliveryFee(charges[0].DeliveryFees);
      }
    }
  });

  const date = new Date();
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getTotalAmount = () => {
    let amount = 0.0;
    let qty = 0;

    cart.cartItems.map((x) => {
      amount = amount + x.ItemTotal;
      qty = qty + x.Count;

      if (x.Answers.length > 0) {
        x.Answers.map((y) => {
          // multiple the answer price with quantity of the item
          if (y.Price) amount = amount + Number(y.Price) * x.Count;
        });
      }
    });

    let tax = Math.round((amount * store.TaxPercent) / 100);

    setTaxAmount(tax);
    setTotal(amount);
    setQuantity(qty);
  };

  useEffect(() => {
    getTotalAmount();
  });

  useEffect(() => {
    if (props.print) {
      setIsPrePaymentInvoice((current) => {
        return false;
      });
      window?.ReactNativeWebView?.postMessage(JSON.stringify(printingProps));

      submitTransaction();
    }
  }, [props.print]);

  useEffect(() => {
    if (props.isPrePaymentInvoice === true && cart.cartItems.length > 0) {
      setIsPrePaymentInvoice(true);
    } else {
      props.printingDone();
    }
  }, [props.isPrePaymentInvoice]);

  useEffect(() => {
    if (isPrePaymentInvoice === true) {
      const process = async () => {
        window?.ReactNativeWebView?.postMessage(JSON.stringify(printingProps));
        await handlePrint();
        setIsPrePaymentInvoice(false);
        props.printingDone();
      };

      process();
    }
  }, [isPrePaymentInvoice]);

  const handleClose = () => {
    setOpen(false);
    handleNumber("");
    dispatch({ type: "CANCELTRANSACTION" });
    props.handleEnterPress();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const submitTransaction = async () => {
    let dateTime = new Date();

    let deliveryAddress = {
      customerName: cart.customerInfo.CustomerName,
      phoneNumber: cart.customerInfo.PhoneNumber,
      email: cart.customerInfo.Email,
      streetAddress: cart.customerInfo.StreetAddress,
      deliveryArea: cart.customerInfo.DeliveryArea,
      cityId: store.CityId,
      countryId: store.CountryId,
    };

    if (cart.cartItems.length > 0) {
      const orderItems = cart.cartItems.map((item) => ({
        MenuItemId: item.MenuItemId,
        ActualPrice: item.Price,
        Quantity: item.Count,
        TotalPrice: item.ItemTotal,
        FinalPrice: item.ItemTotal,
        Answers: item.Answers,
        offerAmount: item.discount,
        offerPercent: item.discountPercent,
        adhocDiscountPer: item.adhocDiscountPer,
        adhocDiscountAmount: item.disc,
      }));

      const storeOrder = {
        TransID: cart.TransID,
        OrderNumber: cart.TransID,
        storeId: store.StoreId,
        OrderType: cart.orderType,
        OrderSource: 2,
        deliveryCharge: cart.orderType === 0 ? deliveryFee : 0,
        total: total,
        tax: taxAmount,
        discountPercent: discountPercent,
        discountAmount: (total / 100) * adhocDiscountPer + discount,
        adhocDiscountPer: adhocDiscountPer,
        adhocDiscountAmount: (total / 100) * adhocDiscountPer,
        //discountAmount: (total / 100) * adhocDiscountPer + discount,
        netPayable: TotalAmount,
        orderItem: orderItems,
        remarks: "",
        date: dateTime,
        deliveryAddress: cart.orderType === 0 ? deliveryAddress : null,
        paymentMode: cart.paymentMode,
      };

      console.log("Placing order: ", storeOrder);

      await placeOrder(storeOrder, token)
        .then((res) => {
          setRevenueAuthorityID(res.SRBInvoiceID);

          // props.printingDone();
        })
        .catch((e) => console.log(e));

      await handlePrint();
      props.printingDone();

      if (number > TotalAmount) {
        handleOpen();
      } else {
        handleNumber("");
        dispatch({ type: "CANCELTRANSACTION" });
        props.handleEnterPress();
      }
    }
  };

  const TotalAmount =
    cart.orderType === 0
      ? deliveryFee +
        total -
        ((total / 100) * adhocDiscountPer + discount) +
        taxAmount
      : total - ((total / 100) * adhocDiscountPer + discount) + taxAmount;

  const orderType = ["Delivery", "TakeAway", "Drive-Thru", "Dine In"];

  const getKitchenOrderTickets = () => {
    const itemsGroupByCategory = cart.cartItems.reduce(function (r, item) {
      r[item.MenuCategoryId] = r[item.MenuCategoryId] || [];
      r[item.MenuCategoryId].push(item);
      return r;
    }, Object.create(null));

    let KOTs = [];

    for (const [menuCategoryId, items] of Object.entries(
      itemsGroupByCategory
    )) {
      const menuCategory = menu.find((x) => x.MenuCategoryId == menuCategoryId);
      const categoryName = menuCategory ? menuCategory.CategoryName : "";

      KOTs.push(
        <Container className={classes.pageBreak} key={"kot-" + KOTs.length}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  fontWeight: "bold",
                }}
              >
                KOT #{" "}
                {kotGenerator.getCrntTicketNumber(parseInt(menuCategoryId))}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ textAlign: "left", fontSize: 11 }}>
                {moment(date).format("DD-MMM-yyyy hh:mm A")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ textAlign: "right", fontSize: 11 }}>
                employee: {FirstName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {categoryName}
              </Typography>
            </Grid>
            {items.map((item, index) => {
              return (
                <div key={"key-hr-kot-item-" + index} style={{ width: "100%" }}>
                  <Grid item xs={12}>
                    <Typography style={{ textAlign: "Left", fontSize: 12 }}>
                      {item.Count} x {item.Name}
                    </Typography>
                  </Grid>
                  {item.Answers &&
                    item.Answers.map((a, i) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          key={"key-hr-kot-item-ans-" + index + "-" + i}
                        >
                          <Typography
                            style={{
                              fontSize: 11,
                              textAlign: "left",
                              marginLeft: 20,
                            }}
                          >
                            {a.answer}
                          </Typography>
                        </Grid>
                      );
                    })}
                </div>
              );
            })}
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ textAlign: "left" }}>
                {items.reduce(function (a, item) {
                  return a + item.Count;
                }, 0)}
              </Typography>
              <Typography style={{ textAlign: "center" }}>
                Order #: {cart.TransID}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
          </Grid>
        </Container>
      );
    }

    return KOTs;
  };

  const printingProps = {
    store,
    cart,
    FirstName,
    discount,
    adhocDiscountPer,
    selectedTableNumber,
    total,
    taxAmount,
    deliveryFee,
    TotalAmount,
    orderType,
    date,
    month,
    number,
    quantity,
    revenueAuthorityID,
    isPrePaymentInvoice,
  };

  return (
    <div style={{ display: "none" }}>
      <Paper className={classes.printerpaper} ref={componentRef}>
        <Container className={classes.pageBreak}>
          {store.ReceiptFormatType === 1 && (
            <ReceiptFormat1 {...printingProps} />
          )}
          {store.ReceiptFormatType === 2 && (
            <ReceiptFormat2 {...printingProps} />
          )}
        </Container>

        {store.KOTPrintingAllowed &&
          Number(cart.orderType) !== 3 &&
          getKitchenOrderTickets()}
      </Paper>

      <ChangeDialoge
        open={open}
        handleClose={handleClose}
        number={number}
        TotalAmount={TotalAmount}
        Currency={store.Currency}
      />
    </div>
  );
};

export default HiddenReceipt;
