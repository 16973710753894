import React from "react";
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const DrawerComponent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const signOut = () => {
    dispatch({ type: "LOGOUT" });
  };

  return (
    <Drawer open={props.open} onClose={props.toggle} anchor="left">
      <List>
        <ListItem button onClick={() => history.push("/")}>
          <ListItemText primary="Home" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => history.push("/menu")}>
          <ListItemText primary="Open Menu" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => history.push("/orderlist")}>
          <ListItemText primary="Order List" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => signOut()}>
          <ListItemText primary="Sign Out" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default DrawerComponent;
