import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import Reducer from "./Store/Reducer";
import swDev from "./swDev";
const middleWare = [thunk];

const emptyTableOrder = {
  TransID: 0,
  orderType: 3,

  cartItems: [],
  customerInfo: [],

  subTotal: 0,
  discount: 0,
  adhocDiscountPer: 0,
  taxAmount: 0,
  netPayable: 0,
};

const defaultState = {
  store: [],
  menu: [],
  selectedCategory: "",
  customers: [],
  cartItems: {
    TransID: 0,
    cartItems: [],
    orderType: 1,
    customerInfo: [],

    subTotal: 0,
    discount: 0,
    adhocDiscountPer: 0,
    taxAmount: 0,
    netPayable: 0,

    isSavedOrder: false,
  },
  holdTransactions: [],
  isDisconected: true,
  OrderList: [],
  isLogedIn: false,
  token: "",
  FirstName: "",
  displayOption: "Menu",
  checkItemDiscount: [],
  adhocDiscountPer: 0,
  discount: 0,
  taxAmount: 0,
  showSelectTablePopup: false,

  tables: [],
  kotCart: [],
  selectedTableId: -1,
};

const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    // console.log(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return defaultState;

    let appState = JSON.parse(serializedState);

    if (appState.UserId === undefined) {
      console.log("User Id is undefined");
      appState = {
        ...appState,
        isLogedIn: false,
      };
    }

    if (appState.cartItems.isSavedOrder === "undefined")
      appState = {
        ...appState,
        cartItems: {
          ...appState.cartItems,
          isSavedOrder: false,
          subTotal: 0,
          discount: 0,
          adhocDiscountPer: 0,
          taxAmount: 0,
          netPayable: 0,
        },
      };

    if (!appState.tables)
      appState = {
        ...appState,
        tables: defaultState.tables.slice(),
      };

    if (appState.kotCart) return appState;
    else
      return {
        ...appState,
        kotCart: [],
      };
  } catch (e) {
    console.log(e);
    return defaultState;
  }
};

const initialState = loadFromLocalStorage();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  Reducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleWare))
);

store.subscribe(() => saveToLocalStorage(store.getState()));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
swDev();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
