import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    width: "100%",
    heigth: 440,
  },
  container: {
    // maxHeight: 440,
    heigth: 440,
  },
});

export default function TransactionDataTable(props) {
  const {
    rows,
    close,
    onSelectOrder,
    onUpdateStatus,
    onCancelOrder,
    onPrintOrder,
  } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rowClicked, setRowClicked] = React.useState(0);

  useEffect(() => {
    if (props.printOpen == false) {
      setRowClicked(0);
    }
  }, [props.printOpen]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOnClick = (data, i) => {
    onSelectOrder(data.OrderId);
  };

  const handleRowClick = (data, i) => {
    onSelectOrder(data.OrderId);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Source</TableCell>
              <TableCell>Order Type</TableCell>
              <TableCell>Order #</TableCell>
              <TableCell>Order Date/Time</TableCell>
              <TableCell>Preferred Time</TableCell>
              <TableCell>Customer Detail</TableCell>
              <TableCell>Order Status</TableCell>
              <TableCell>Process</TableCell>
              <TableCell>Print</TableCell>
              <TableCell>Cancel</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.length > 0 &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.DeliveryAddressId ? row.DeliveryAddressId : i}
                      onClick={() => handleOnClick(row, i)}
                    >
                      <TableCell>
                        <Typography>{row.OrderSourceDesc}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.TypeDesc}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.OrderNumber}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {moment(row.Date).format("DD-MMM-yyyy hh:mm A")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {row.IsFuture
                            ? moment(row.FutureDate).format(
                                "DD-MMM-yyyy hh:mm A"
                              )
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {row.Type == 0
                            ? row.DeliveryAddress?.StreetAddress
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.StatusDesc}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {row.NextStatus != null && (
                            <Button
                              variant="outlined"
                              onClick={() =>
                                onUpdateStatus(row.OrderId, row.NextStatusId)
                              }
                              color="primary"
                              disabled={
                                row.IsFuture &&
                                new Date(row.FutureDate).toDateString() !=
                                  new Date().toDateString()
                              }
                            >
                              Mark As {row.NextStatus}
                            </Button>
                          )}
                          {row.NextStatus == null && (
                            <Button variant="outlined" disabled>
                              {row.StatusDesc}
                            </Button>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {" "}
                          <Button
                            variant="outlined"
                            onClick={(e) => {
                              e.stopPropagation();
                              setRowClicked(row.OrderId);
                              onPrintOrder(row.OrderId);
                            }}
                            color="primary"
                            disabled={row.Status == 10}
                          >
                            {rowClicked == row.OrderId && (
                              <CircularProgress size="1rem"></CircularProgress>
                            )}
                            Print
                          </Button>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {" "}
                          <Button
                            variant="outlined"
                            onClick={() => onCancelOrder(row.OrderId)}
                            color="secondary"
                            disabled={row.Status == 10}
                          >
                            Cancel
                          </Button>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={rows ? rows.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
