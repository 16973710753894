import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

const MainCategory = () => {
  const dispatch = useDispatch();

  const { menu } = useSelector((state) => {
    return {
      menu: state.menu,
    };
  });
  const [catId, setCatId] = React.useState(menu[0].MenuCategoryId);
  const onCardClick = (CategoryName, id) => {
    setCatId(id);
    dispatch({ type: "SELECTEDCETEGORY", payload: CategoryName });
  };

  return (
    <div style={{ textAlign: "center", marginTop: 20 }}>
      <Grid container justifyContent="flex-start" spacing={3}>
        {menu.length > 0
          ? menu.map((data) => (
              <Grid item xs={"auto"} key={data.MenuCategoryId}>
                <Paper
                  elevation={3}
                  onClick={() =>
                    onCardClick(data.CategoryName, data.MenuCategoryId)
                  }
                  style={{
                    padding: 10,
                    textAlign: "center",
                    width: 120,
                    cursor: "pointer",

                    background:
                      catId === data.MenuCategoryId
                        ? "rgba(224, 224, 224, 1)"
                        : null,
                    heigth: "100%",
                  }}
                >
                  <Typography style={{ fontSize: 12 }}>
                    {data.CategoryName}
                  </Typography>
                </Paper>
              </Grid>
            ))
          : null}
      </Grid>
    </div>
  );
};

export default MainCategory;
