import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";

import { formatPrice } from "../../helper";
import ReceiptFooter from "./receiptFooter";
import ReceiptHeader from "./receiptHeader";
import ReceiptCustomerInfo from "./receiptCustomerInfo";

const ReceiptFormat1 = (props) => {
  const {
    store,
    cart,
    FirstName,
    discount,
    adhocDiscountPer,
    selectedTableNumber,
    total,
    taxAmount,
    deliveryFee,
    TotalAmount,
    orderType,
    date,
    month,
    number,
    revenueAuthorityID,
  } = props;

  const getItemTotalPrice = (item) => {
    let total = item.ItemTotal;
    item.Answers.map((a, i) => {
      if (a.Price) {
        total += item.Count * parseFloat(a.Price);
      }
    });

    return total;
  };

  return (
    <Grid container style={{ maxWidth: 250 }}>
      <ReceiptHeader store={store} authorityID={revenueAuthorityID} />
      <Grid item xs={12}>
        <div
          style={{
            textAlign: "center",
            width: "100%",
            marginTop: 5,
          }}
        >
          {store.TaxRegistrationNumber &&
            store.TaxRegistrationNumber.length > 0 && (
              <>
                <Typography style={{ fontSize: 12 }}>
                  Tax Registration # {store.TaxRegistrationNumber}
                </Typography>
                <br />
              </>
            )}

          {props.isPrePaymentInvoice && (
            <Typography style={{ fontWeight: "bold" }}>
              Pre Payment Invoice
            </Typography>
          )}

          <Typography style={{ fontSize: 12 }}>
            Invoice Number : {cart.TransID}
          </Typography>

          {revenueAuthorityID && revenueAuthorityID !== "" && (
            <Typography style={{ fontSize: 12 }}>
              SRB Invoice ID : {revenueAuthorityID}
            </Typography>
          )}

          {cart.orderType === 3 && selectedTableNumber > -1 && (
            <Typography style={{ fontSize: 12 }}>
              Table # : {selectedTableNumber}
            </Typography>
          )}

          <Typography style={{ fontSize: 12, fontWeight: "bold" }}>
            {orderType[Number(cart.orderType)]?.toUpperCase()}
          </Typography>
          <br />
        </div>
      </Grid>
      <Grid item xs={6}>
        <Typography style={{ textAlign: "left", fontSize: 12 }}>
          {moment().format("DD-MMM-yyyy hh:mm A")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography style={{ textAlign: "right", fontSize: 12 }}>
          employee: {FirstName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      {cart.orderType === 0 && (
        <>
          <ReceiptCustomerInfo customer={cart.customerInfo} />
          <Grid item xs={12}>
            <hr />
          </Grid>
        </>
      )}
      {cart.cartItems.map((data, index) => (
        <Grid item xs={12} key={"key-hr-item-" + index}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ textAlign: "Left", fontSize: 12 }}>
              {data.Count} x {data.Name}
            </Typography>
            <Typography style={{ textAlign: "right", fontSize: 12 }}>
              {formatPrice(getItemTotalPrice(data))}
            </Typography>
          </div>
          {data.Answers
            ? data.Answers.map((a, i) => {
                return (
                  <Grid
                    key={"key-hr-ans-" + index + "-" + i}
                    container
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={8}>
                      <Typography style={{ fontSize: 11, marginLeft: 20 }}>
                        {a.answer}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            : null}
        </Grid>
      ))}
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={8}>
        <Typography style={{ textAlign: "right", fontSize: 12 }}>
          Sub Total:
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography style={{ textAlign: "right", fontSize: 12 }}>
          {store.Currency} {formatPrice(total)}
        </Typography>
      </Grid>
      {adhocDiscountPer !== 0 || discount !== 0 ? (
        <>
          <Grid item xs={8}>
            <Typography style={{ textAlign: "right", fontSize: 12 }}>
              Discount:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ textAlign: "right", fontSize: 12 }}>
              {store.Currency} -
              {formatPrice((total / 100) * adhocDiscountPer + discount)}
            </Typography>
          </Grid>
        </>
      ) : null}
      {cart.orderType === 0 ? (
        <>
          <Grid item xs={8}>
            <Typography style={{ textAlign: "right", fontSize: 12 }}>
              Delivery Fee:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ textAlign: "right", fontSize: 12 }}>
              {store.Currency} {formatPrice(deliveryFee)}
            </Typography>
          </Grid>
        </>
      ) : null}
      {taxAmount > 0 && (
        <>
          <Grid item xs={8}>
            <Typography style={{ textAlign: "right", fontSize: 12 }}>
              Tax:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ textAlign: "right", fontSize: 12 }}>
              {store.Currency} {formatPrice(taxAmount)}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={8}>
        <Typography style={{ textAlign: "right", fontSize: 12 }}>
          Total:
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography style={{ textAlign: "right", fontSize: 12 }}>
          {store.Currency} {formatPrice(TotalAmount)}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography style={{ textAlign: "right", fontSize: 12 }}>
          Customer Paid:
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography style={{ textAlign: "right", fontSize: 12 }}>
          {store.Currency}{" "}
          {formatPrice(
            number === "" || number === undefined ? TotalAmount : number
          )}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography style={{ textAlign: "right", fontSize: 12 }}>
          Return Amount:
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography style={{ textAlign: "right", fontSize: 12 }}>
          {store.Currency}
          {formatPrice(
            TotalAmount -
              Number(
                number === "" || number === undefined ? TotalAmount : number
              )
          )}
        </Typography>
      </Grid>

      <ReceiptFooter store={store} />
    </Grid>
  );
};

export default ReceiptFormat1;
