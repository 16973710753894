import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import OrderCard from "./OrderCard";
import { getPendingOrders, updateOrderStatus } from "../../api/service";
import { makeStyles } from "@material-ui/core/styles";

export default function PendingTransactionsDialog(props) {
  const { open, handleClose, onCompleted } = props;
  const [pendingOrders, setPendingOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { store, token } = useSelector((state) => {
    return {
      store: state.store,
      token: state.token,
    };
  });

  const fetchData = async () => {
    const pendingOrders = await getPendingOrders(store.StoreId, token);
    if (pendingOrders?.length == 0) {
      onCompleted();
    }
    setPendingOrders(pendingOrders);
    setIsLoading(false);
  };

  const onStatusUpdateClick = async (orderId, currentStatus) => {
    const newStatus = await updateOrderStatus(orderId, currentStatus);
    fetchData();
  };

  useEffect(() => {
    if (open) {
      fetchData();
      const interval = setInterval(() => {
        fetchData();
      }, 60000); //1 minute
      return () => clearInterval(interval);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Pending Transactions</DialogTitle>

      {isLoading ? (
        <CircularProgress color="secondary" style={{ alignSelf: "center" }} />
      ) : (
        <DialogContent>
          <Grid container columns={{ xs: 1, sm: 1, md: 12 }} spacing={2}>
            {pendingOrders?.map((order, index) => {
              return (
                <Grid
                  item
                  xs={8}
                  sm={12}
                  md={12}
                  lg={12}
                  spacing={1}
                  key={index}
                >
                  <OrderCard
                    order={order}
                    statusUpdate={onStatusUpdateClick}
                  ></OrderCard>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
