import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Grid } from "@material-ui/core";

const HoldTransaction = () => {
  const dispatch = useDispatch();
  const { holdTransactions, cart } = useSelector((state) => {
    return {
      holdTransactions: state.holdTransactions,
      cart: state.cartItems,
    };
  });

  const retriveTransaction = (index) => {
    if (cart.TransID === 0) {
      const payload = {
        cart: holdTransactions[index],
        hold: holdTransactions.filter((value, i, arr) => {
          if (i !== index) {
            return value;
          }
        }),
      };
      dispatch({ type: "RETRIVETRANSACTION", payload: payload });
    }
  };
  // console.log(holdTransactions)
  return (
    <Paper style={{ padding: 10, height: 60, overflowY: "scroll" }}>
      <Grid container spacing={3}>
        {holdTransactions &&
          holdTransactions.map((data, i) => (
            <Grid item xs={3} key={i} onClick={() => retriveTransaction(i)}>
              <Paper style={{ background: "black", color: "white" }}>
                {`${data.name} - ${data.TransID}`}
              </Paper>
            </Grid>
          ))}
      </Grid>
    </Paper>
  );
};

export default HoldTransaction;
