import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  Container,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { formatPrice } from "../../helper";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";

const FinishTransaction = (props) => {
  const { number, handleNumber } = props;
  const dispatch = useDispatch();
  const [deliveryFee, setDeliveryFee] = React.useState(0);
  const [AmountInput, setAmountInput] = useState("");
  const [FinalAmount, setFinalAmount] = useState(0);
  const [subTotal, setSubTotal] = React.useState(0);
  // const [discount, setDiscount] = React.useState(0);
  const [taxAmount, setTaxAmount] = React.useState(0);
  const [Total, setTotal] = React.useState(0);

  const amountFinal = () => {
    setFinalAmount(AmountInput);
    handleNumber(AmountInput);
    setAmountInput("");
  };
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      amountFinal();
    }
  };
  const { store, cart, FirstName, token, discount } = useSelector((state) => {
    return {
      store: state.store,
      cart: state.cartItems,
      FirstName: state.FirstName,
      token: state.token,
      discount: state.discount,
    };
  });

  React.useEffect(() => {
    let deliveryLocation = cart.customerInfo.DeliveryArea
      ? cart.customerInfo.DeliveryArea
      : "";
    if (cart.orderType === 0) {
      let charges = store.StoreDeliveryFees.filter(
        (data) => data.LocationName === deliveryLocation
      );
      console.log(charges);
      if (charges.length > 0) {
        setDeliveryFee(charges[0].DeliveryFees);
      }
    }
  });

  React.useEffect(() => {
    getSubTotalAmount();
  });
  const getSubTotalAmount = () => {
    let amount = 0;
    let taxAmount = 0;
    let discount = 0;
    cart.cartItems.map((x) => {
      amount = amount + x.ItemTotal;
      if (x.Answers.length > 0) {
        x.Answers.map((y) => {
          if (y.Price) amount = amount + Number(y.Price);
        });
      }
    });
    setSubTotal(amount);
    setTotal(amount + taxAmount - discount);
  };

  const TotalAmount =
    cart.orderType === 0 ? deliveryFee + Total - discount : Total - discount;

  return (
    <Paper style={{ marginBottom: 10, marginTop: 15 }}>
      <Container style={{ padding: 3 }}>
        <Paper style={{ marginTop: 30, padding: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h5" style={{ textAlign: "right" }}>
                    Amount Due:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" style={{ textAlign: "left" }}>
                    {store.Currency + " " + formatPrice(TotalAmount)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h5" style={{ textAlign: "right" }}>
                    Received:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" style={{ textAlign: "left" }}>
                    {store.Currency + " " + formatPrice(FinalAmount)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h5" style={{ textAlign: "right" }}>
                    Pending to Pay:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" style={{ textAlign: "left" }}>
                    {store.Currency + " " + formatPrice(Total - FinalAmount)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      <Container>
        <Paper style={{ padding: 10, marginTop: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={"auto"}>
              <Paper
                elevation={3}
                style={{ width: 105, textAlign: "center", padding: 10 }}
              >
                <LocalAtmIcon style={{ fontSize: 30 }} />
                <Typography style={{ fontSize: 13 }}>Cash</Typography>
              </Paper>
            </Grid>
            <Grid item xs={"auto"}>
              <Paper
                elevation={3}
                style={{ width: 105, textAlign: "center", padding: 10 }}
              >
                <LocalAtmIcon style={{ fontSize: 30 }} />
                <Typography style={{ fontSize: 13 }}>Credit Card</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Paper style={{ marginTop: 20, padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Grid container spacing={1} style={{ padding: 15 }}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                  type="number"
                  fullWidth
                  onChange={(e) => setAmountInput(e.target.value)}
                  value={AmountInput}
                  onKeyPress={(e) => handleEnter(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setAmountInput(AmountInput + "1")}
                >
                  1
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setAmountInput(AmountInput + "2")}
                >
                  2
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setAmountInput(AmountInput + "3")}
                >
                  3
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setAmountInput(AmountInput + "4")}
                >
                  4
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setAmountInput(AmountInput + "5")}
                >
                  5
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setAmountInput(AmountInput + "6")}
                >
                  6
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setAmountInput(AmountInput + "7")}
                >
                  7
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setAmountInput(AmountInput + "8")}
                >
                  8
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setAmountInput(AmountInput + "9")}
                >
                  9
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setAmountInput(AmountInput + "0")}
                >
                  0
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setAmountInput(AmountInput + ".")}
                >
                  .
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => amountFinal()}
                >
                  ENTER
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Paper style={{ height: "100%", width: 600 }}></Paper>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export default FinishTransaction;
