import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Box,
} from "@material-ui/core";
// import "date-fns";
// import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import Xreport from "./xReport";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReportDialogeBox = (props) => {
  const { open, handleClose } = props;
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showReportClick, setShowReportClicked] = useState(false);
  const [report, setReport] = useState([
    { name: "X Report", selected: true },
    { name: "Z Report", selected: false },
    { name: "Shift Report", selected: false },
  ]);
  const handleSelectedReport = (index) => {
    let reportButton = report.map((data, i) => {
      if (i === index) {
        data.selected = true;
        return data;
      } else {
        data.selected = false;
        return data;
      }
    });
    setReport(reportButton);
  };

  return (
    <Dialog
      open={open}
      fullScreen
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Select Report Option
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Container>
          <Box style={{ marginTop: "" }}>
            <Grid container spacing={3}>
              {report.map((data, i) => (
                <Grid item xs={4} key={i}>
                  <Button
                    color="primary"
                    variant={data.selected ? "contained" : "outlined"}
                    fullWidth
                    onClick={() => handleSelectedReport(i)}
                  >
                    {data.name}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={4}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="Start-Date"
                    label="Start Date"
                    color="primary"
                    value={startDate}
                    fullWidth
                    onChange={(date) => setStartDate(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="Start-Date"
                    label="Start Date"
                    color="primary"
                    value={endDate}
                    fullWidth
                    onChange={(date) => setEndDate(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => setShowReportClicked(!showReportClick)}
                    color="primary"
                    fullWidth
                    variant="contained"
                    style={{ marginTop: 20 }}
                  >
                    Show Report
                  </Button>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Box>
        </Container>

        {showReportClick && (
          <Container>
            <Xreport />
          </Container>
        )}
      </DialogContent>

      {/*       <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleClose} color="primary">
          Print
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default ReportDialogeBox;
