import React, { useState, useEffect } from "react";

export function LazyBackground(props) {
  const [source, setSource] = useState(process.env.PUBLIC_URL +'/asset/placeholder.png');

  useEffect(() => {
    const img = new Image();
    img.src = props.src;
    img.onload = () => setSource(props.src);
  }, [props.src]);

  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL +'/asset/placeholder.png' })`,
        height: !props.height ? "auto" : props.height,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${source})`,
          height: !props.height ? "auto" : props.height,
          width:!props.height ? "auto" : props.width,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
