import moment from "moment";

class kotNumberGenerator {
  #today;
  #kotNumbers;

  constructor() {
    this.today = new Date();

    this.kotNumbers =
      localStorage.getItem("kotnums") === null
        ? []
        : JSON.parse(localStorage.getItem("kotnums")).date ===
          moment(this.today).format("DD/MM")
        ? JSON.parse(localStorage.getItem("kotnums")).kots
        : [];
  }

  generateNextNumber = (forKitchen) => {
    let kotIndex = this.kotNumbers.findIndex((x) => x.kitchenId === forKitchen);
    let kitchenKOT;

    if (kotIndex > -1) {
      // remove element from the array
      let delkitchenKOT = this.kotNumbers.splice(kotIndex, 1);

      kitchenKOT = {
        ticketNumber: delkitchenKOT[0].ticketNumber + 1,
        kitchenId: forKitchen,
      };
    } else
      kitchenKOT = {
        ticketNumber: 1,
        kitchenId: forKitchen,
      };

    this.kotNumbers.push(kitchenKOT);

    const kotnums = {
      date: moment(this.today).format("DD/MM"),
      kots: this.kotNumbers,
    };

    localStorage.setItem("kotnums", JSON.stringify(kotnums));
  };

  getCrntTicketNumber = (forKitchen) => {
    let kitchenKOT = this.kotNumbers.find((x) => x.kitchenId === forKitchen);

    if (kitchenKOT) {
      return kitchenKOT.ticketNumber;
    } else return -1;
  };

  doesTicketNumberExists = (forKitchen) => {
    return this.kotNumbers.findIndex((x) => x.id === forKitchen) > -1;
  };
}

export const kotGenerator = new kotNumberGenerator();
