import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Typography,
  Paper,
  Grid,
  Menu,
  MenuItem,
  Divider,
} from "@material-ui/core";

import SettingsIcon from "@material-ui/icons/Settings";
import AssessmentIcon from "@material-ui/icons/Assessment";
import DescriptionIcon from "@material-ui/icons/Description";
import SearchIcon from "@material-ui/icons/Search";
import PersonIcon from "@material-ui/icons/Person";
import DialpadIcon from "@material-ui/icons/Dialpad";
import CancelIcon from "@material-ui/icons/Cancel";

import CustomerDialogBox from "../CustomerDialogBox/CustomerDialogBox";
import ReportDialogeBox from "../reportDialogeBox/reportDialoge";
import { getXReportData } from "../../api/service";
import BottomButtonGroup from "./BottomButton";

const ButtonGroup = () => {
  const dispatch = useDispatch();
  const { cart, customers, isSavedOrder, store, token, displayOption } =
    useSelector((state) => {
      return {
        cart: state.cartItems,
        customers: state.customers,
        isSavedOrder: state.cartItems.isSavedOrder,
        store: state.store,
        token: state.token,
      };
    });

  const [open, setOpen] = React.useState(false);
  const [openReport, setOpenReport] = React.useState(false);
  const [customerName, setCustomerName] = React.useState("");
  const [StringLength] = React.useState(8);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showReportMenu, setShowReportMenu] = React.useState(null);

  useEffect(() => {
    // making sure customerInfo is not empty
    if (cart.customerInfo.CustomerName) {
      if (!cart.customerInfo.CustomerName) {
        setCustomerName("");
      } else if (cart.customerInfo.CustomerName) {
        setCustomerName(
          cart.customerInfo.CustomerName.length > StringLength
            ? cart.customerInfo.CustomerName.substring(0, StringLength - 3) +
                "..."
            : cart.customerInfo.CustomerName
        );
      }
    }
  }, [cart, StringLength]);

  const handleClick = (event) => {
    // if current cart is not a SAVED order
    if (!isSavedOrder) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openCustomerDialog = () => {
    setOpen(!open);
  };

  const closeCustomerDialog = (index) => {
    console.log({ index });

    if (typeof index === "number") {
      if (index === -1)
        if (customers.length > 0) index = 0;
        else {
          setOpen(!open);
          return;
        }

      console.log({ index });

      setCustomerName(
        customers[index].CustomerName.length > StringLength
          ? customers[index].CustomerName.substring(0, StringLength - 3) + "..."
          : customers[index].CustomerName
      );
    } else if (cart.customerInfo.CustomerName) {
      dispatch({ type: "ORDERTYPE", payload: 0 });
    } else {
      dispatch({ type: "ORDERTYPE", payload: 1 });
    }
    setOpen(!open);
  };

  const updateorderType = (type) => {
    handleClose();

    switch (type) {
      case 0:
        dispatch({ type: "ORDERTYPE", payload: type });
        openCustomerDialog();
        break;
      case 1:
        dispatch({ type: "ORDERTYPE", payload: type });
        break;
      case 3:
        dispatch({ type: "SHOWTABLEPOPUP" });
        break;
    }
  };

  const handleCloseReport = () => {
    setOpenReport(false);
  };

  const showSelectedOrderType = () => {
    switch (cart.orderType) {
      case 0:
        return "Delivery";
      case 1:
        return "Take Away";
      case 3:
        return "DineIn";
    }
  };

  const printXReport = async () => {
    setShowReportMenu(null);

    const data = await getXReportData(store.StoreId, token);
    console.log({ data });

    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ messageType: "xreport", data: data })
    );

    alert("Data sent to printer");
  };

  const signOut = () => {
    console.log("exit");
    dispatch({ type: "LOGOUT" });
  };

  const backToMenu = () => {
    dispatch({ type: "BACKTOMENU" });
  };

  if (cart)
    return (
      <Paper style={{ marginLeft: 10, paddingLeft: 10, marginTop: 20 }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={"auto"}>
            <Paper
              elevation={3}
              style={{
                width: 80,
                textAlign: "center",
                padding: 8,
                cursor: "pointer",
              }}
              onClick={signOut}
            >
              <CancelIcon style={{ fontSize: 25, color: "red" }} />

              <Typography style={{ fontSize: 12, color: "red" }}>
                Exit
              </Typography>
            </Paper>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{ marginLeft: 8, marginRight: 8 }}
          />
          <Grid item xs={"auto"}>
            <Paper
              elevation={3}
              style={{
                width: 80,
                textAlign: "center",
                padding: 10,
                cursor: "pointer",
              }}
              onClick={(event) => setShowReportMenu(event.currentTarget)}
            >
              <AssessmentIcon style={{ fontSize: 25 }} />
              <Typography style={{ fontSize: 12 }}>Reports</Typography>
            </Paper>
            <Menu
              id="report-menu"
              anchorEl={showReportMenu}
              keepMounted
              open={Boolean(showReportMenu)}
              onClose={() => setShowReportMenu(null)}
            >
              <MenuItem onClick={printXReport}>X Report</MenuItem>
            </Menu>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{ marginLeft: 8, marginRight: 8 }}
          />
          <Grid item xs={"auto"}>
            <Paper
              elevation={cart.orderType && cart.orderType !== 0 ? 0 : 3}
              style={{
                width: 80,
                textAlign: "center",
                padding: 10,
                backgroundColor:
                  cart.orderType !== 0 ? "rgba(0, 0, 0, 0.12)" : "white",
                color: cart.orderType !== 0 ? "rgba(0, 0, 0, 0.26)" : "black",
              }}
              onClick={cart.orderType === 0 ? openCustomerDialog : null}
            >
              <PersonIcon style={{ fontSize: 25 }} />
              <Typography style={{ fontSize: 12 }}>
                {cart.orderType === 0 ? customerName : "Customer"}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={"auto"}>
            <Paper
              elevation={3}
              style={{
                width: 80,
                textAlign: "center",
                padding: 10,
                backgroundColor: "#233694",
                color: "white",
                cursor: "pointer",
              }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <DialpadIcon style={{ fontSize: 25 }} />
              <Typography style={{ fontSize: 12 }}>
                {showSelectedOrderType()}
              </Typography>
            </Paper>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {store.StoreOrderDispatchType?.map((orderType) => {
                if (orderType.DispatchTypeId == 1) {
                  return (
                    <MenuItem onClick={() => updateorderType(0)}>
                      Delivery
                    </MenuItem>
                  );
                } else if (orderType.DispatchTypeId == 2) {
                  return (
                    <MenuItem onClick={() => updateorderType(1)}>
                      Take Away
                    </MenuItem>
                  );
                } else if (orderType.DispatchTypeId == 4) {
                  return (
                    <MenuItem onClick={() => updateorderType(3)}>
                      DineIn
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </Grid>
          <Grid item xs={"auto"}>
            <BottomButtonGroup />
          </Grid>
        </Grid>
        <CustomerDialogBox open={open} handleClose={closeCustomerDialog} />
        <ReportDialogeBox open={openReport} handleClose={handleCloseReport} />
      </Paper>
    );
  else return null;
};

export default ButtonGroup;
