import { callAPI } from "./apiCalls";

export const validateSubdomain = async (domainName, token) => {
  try {
    return await callAPI("store/byuri/" + domainName, token, null, "GET");
  } catch (ex) {
    try {
      const serializedState = localStorage.getItem("state");
      if (serializedState === null) return [];
      return JSON.parse(serializedState).store;
    } catch (e) {
      console.log(e);
      return { StoreId: 0 };
    }
  }
};

export const getMenuItems = async (storeId, token) => {
  try {
    return await callAPI(
      "menuitem/bystoreforcart/" + storeId + "/-1/2",
      token,
      null,
      "GET"
    );
  } catch (ex) {
    try {
      const serializedState = localStorage.getItem("state");
      if (serializedState === null) return [];
      return JSON.parse(serializedState).menu;
    } catch (e) {
      console.log(ex);
      return [];
    }
  }
};

export const getCustomers = async (storeId, token) => {
  try {
    return await callAPI("Customer/ByStore/" + storeId, token, null, "GET");
  } catch (ex) {
    try {
      const serializedState = localStorage.getItem("state");
      if (serializedState === null) return [];
      return JSON.parse(serializedState).customer;
    } catch (e) {
      console.log(ex);
      return [];
    }
  }
};

export const placeOrder = async (storeOrderData, token, forCache = false) => {
  try {
    console.log("api Call", storeOrderData);
    return await callAPI("order/placeorder/", token, storeOrderData, "POST");
  } catch (ex) {
    // skip if data is being processed from cache
    if (!forCache) {
      console.log("Store In LocalStorage", storeOrderData);

      let localTransaction = localStorage.getItem("transactions");

      if (localTransaction === null) {
        let storeOrderArray = [storeOrderData];
        localStorage.setItem("transactions", JSON.stringify(storeOrderArray));
      } else {
        localTransaction = JSON.parse(localTransaction);

        // if an order of same value and date already doesn't exists then offline store that order
        // duplicate order check in the offline DB
        if (
          localTransaction.findIndex(
            (x) =>
              x.date === storeOrderData.date && x.total === storeOrderData.total
          ) === -1
        ) {
          localTransaction.push(storeOrderData);
          localStorage.setItem(
            "transactions",
            JSON.stringify(localTransaction)
          );
        } else console.log("Order already present in local cache.");
      }
    }

    console.log(ex);
    return { OrderId: -1 };
  }
};

export const getOrderList = async (storeId, token) => {
  try {
    return await callAPI("Order/ByStore/" + storeId, token, null, "GET");
  } catch (ex) {
    try {
      const serializedState = localStorage.getItem("state");
      if (serializedState === null) return [];
      return JSON.parse(serializedState).OrderList;
    } catch (e) {
      console.log(ex);
      return [];
    }
  }
};

export const getOrderStatus = async (transNumber) => {
  try {
    return await callAPI(
      "order/statusbytransnumber/" + transNumber,
      "",
      null,
      "GET"
    );
  } catch (ex) {
    console.log(ex);
    return { OrderId: -1 };
  }
};

export const saveSignUpInfo = async (signupData) => {
  try {
    return await callAPI("RegisterUser", "", signupData, "POST");
  } catch (ex) {
    console.log(ex);
    return "Error";
  }
};
//
export const getAllLogo = async () => {
  try {
    // return await callAPI("Store/StoreNameAndURL", "", null, "GET");
    return await callAPI("Store/SliderDetails", "", null, "GET");
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const getLiveOrders = async (
  storeId,
  orderSource,
  orderType,
  orderStatus,
  token
) => {
  try {
    return await callAPI(
      "Order/Live/" +
        storeId +
        "?Source=" +
        orderSource +
        "&Type=" +
        orderType +
        "&Status=" +
        orderStatus,
      token,
      null,
      "GET"
    );
  } catch (ex) {
    try {
      const serializedState = localStorage.getItem("state");
      if (serializedState === null) return [];
      return JSON.parse(serializedState).customer;
    } catch (e) {
      console.log(ex);
      return [];
    }
  }
};

export const getPendingOrdersCount = async (storeId, token) => {
  try {
    return await callAPI("Order/Live/Count/" + storeId, token, null, "GET");
  } catch (ex) {
    try {
      const serializedState = localStorage.getItem("state");
      if (serializedState === null) return [];
      return JSON.parse(serializedState).customer;
    } catch (e) {
      console.log(ex);
      return [];
    }
  }
};

export const getPendingOrders = async (storeId, token) => {
  try {
    return await callAPI("Order/Pending/" + storeId, token, null, "GET");
  } catch (ex) {
    try {
      const serializedState = localStorage.getItem("state");
      if (serializedState === null) return [];
      return JSON.parse(serializedState).customer;
    } catch (e) {
      console.log(ex);
      return [];
    }
  }
};

export const getOrderDetails = async (orderId, token) => {
  try {
    return await callAPI("Order/" + orderId, token, null, "GET");
  } catch (ex) {
    try {
      const serializedState = localStorage.getItem("state");
      if (serializedState === null) return [];
      return JSON.parse(serializedState).customer;
    } catch (e) {
      console.log(ex);
      return [];
    }
  }
};

export const updateOrderStatus = async (orderId, currentStatus, token) => {
  try {
    return await callAPI(
      "Order/" + orderId + "/" + currentStatus,
      token,
      null,
      "PUT"
    );
  } catch (ex) {
    try {
      const serializedState = localStorage.getItem("state");
      if (serializedState === null) return [];
      return JSON.parse(serializedState).customer;
    } catch (e) {
      console.log(ex);
      return [];
    }
  }
};

export const cancelOrder = async (orderId, reason, token) => {
  try {
    return await callAPI(
      "Order/CancelOrder/" + orderId + "/" + reason,
      token,
      null,
      "PUT"
    );
  } catch (ex) {
    try {
      const serializedState = localStorage.getItem("state");
      if (serializedState === null) return [];
      return JSON.parse(serializedState).customer;
    } catch (e) {
      console.log(ex);
      return [];
    }
  }
};

export const loginApi = async (loginData) => {
  try {
    return await callAPI("Account/SimpleLogin", "", loginData, "POST");
  } catch (ex) {
    console.log(ex);
    return "Error";
  }
};

export const getBrandData = async (userId, token) => {
  try {
    return await callAPI(
      "Account/GetUserBrandData/" + userId + "/2",
      token,
      null,
      "GET"
    );
  } catch (ex) {
    try {
      const serializedState = localStorage.getItem("state");
      if (serializedState === null) return {};
      const storeData = JSON.parse(serializedState).store;
      return { Stores: [storeData] };
    } catch (e) {
      console.log(ex);
      return {};
    }
  }
};
//

export const getXReportData = async (storeId, token) => {
  try {
    return await callAPI("report/xreport/" + storeId, token, null, "GET");
  } catch (ex) {
    alert(ex);
  }
};

export const storeVoidOrder = async (
  storeOrderData,
  token,
  forCache = false
) => {
  try {
    console.log("api Call", storeOrderData);
    return await callAPI(
      "order/StoreVoidOrder/",
      token,
      storeOrderData,
      "POST"
    );
  } catch (ex) {
    console.log("failed storeVoidOrder", ex);
    // skip if data is being processed from cache
    if (!forCache) {
      let localVoidTransactions = localStorage.getItem("voidTransactions");

      if (localVoidTransactions === null) {
        let storeOrderArray = [storeOrderData];
        localStorage.setItem(
          "voidTransactions",
          JSON.stringify(storeOrderArray)
        );
      } else {
        localVoidTransactions = JSON.parse(localVoidTransactions);

        // if an order of same value and date already doesn't exists then offline store that order
        // duplicate order check in the offline DB
        if (
          localVoidTransactions.findIndex(
            (x) =>
              x.date === storeOrderData.date && x.total === storeOrderData.total
          ) === -1
        ) {
          localVoidTransactions.push(storeOrderData);
          localStorage.setItem(
            "voidTransactions",
            JSON.stringify(localVoidTransactions)
          );
        } else console.log("Order already present in local cache.");
      }
    }

    console.log(ex);
    return { OrderId: -1 };
  }
};
