import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Paper,
  Typography,
  Container,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import { useReactToPrint, onAfterPrint } from "react-to-print";
import ChangeDialoge from "./ChangeDialoge";
import { kotGenerator } from "../../utils/kotNumMaker";
import ReceiptFormat1 from "../printing/ReceiptFormat1";
import ReceiptFormat2 from "../printing/ReceiptFormat2";

const useStyles = makeStyles((theme) => ({
  printerpaper: {
    color: "black",
  },

  pageBreak: {
    "@media print": {
      display: "block",
      pageBreakAfter: "always",
    },
  },
}));

const PrintReceipt = (props) => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: (data) => console.log("after print data: ", data),
  });

  const classes = useStyles();

  const { order, print } = props;
  const [cart, setCart] = React.useState({
    cartItems: [],
    isSavedOrder: false,
    subTotal: 0,
    discount: 0,
    adhocDiscountPer: 0,
    taxAmount: 0,
    netPayable: 0,
  });

  const { store } = useSelector((state) => {
    return {
      store: state.store,
    };
  });

  const makeCart = async () => {
    let cartItemsArray = [];

    order.OrderItem.map((orderItem) => {
      cartItemsArray.push({
        ...orderItem,
        Name: orderItem.ItemName,
        Count: orderItem.Quantity,
        Price: orderItem.FinalPrice,
        ItemTotal: orderItem.TotalPrice,
        selected: true,
        Answers: orderItem.Answers ? orderItem.Answers : [],
        adhocDiscountPer: 0,
      });
    });
    let newCart = {
      TransID: order.OrderNumber,
      adhocDiscountPer: order.AdhocDiscountPer ? order.AdhocDiscountPer : 0,
      orderType: order.Type,
      cartItems: cartItemsArray,
      customerInfo: {
        CustomerName: order.DeliveryAddress?.CustomerName,
        PhoneNumber: order.DeliveryAddress?.PhoneNumber,
        Email: order.DeliveryAddress?.Email,
        StreetAddress: order.DeliveryAddress?.StreetAddress,
        DeliveryArea: order.DeliveryAddress?.DeliveryArea,
      },
      discount: 0,
      netPayable: order.NetPayable,
      taxAmount: order.Tax,
    };

    setCart(newCart);
  };

  useEffect(() => {
    if (order) {
      makeCart();
    }
  }, [order]);

  const startPrint = async () => {
    if (cart.cartItems?.length > 0) {
      await handlePrint();
    }
    props.printingDone();
  };

  React.useEffect(() => {
    try {
      if (print) {
        makeCart();
        window?.ReactNativeWebView?.postMessage(JSON.stringify(printingProps));
        startPrint();
      }
    } catch (ex) {
      console.log(ex);
    }
  }, [print]);

  const orderType = ["Delivery", "TakeAway", "Drive-Thru", "Dine In"];

  const printingProps = {
    store,
    cart,
    discount: order?.DiscountAmount,
    deliveryFee: order?.DeliveryCharge,
    adhocDiscountPer: order?.AdhocDiscountPer,
    taxAmount: order?.Tax,
    TotalAmount: order?.NetPayable,
    orderType,
    total: order?.Total,
    revenueAuthorityID: order?.SRBInvoiceID,
    SNTN: order?.Store?.NTN,
  };

  return (
    <>
      {print && (
        <div style={{ display: "none" }}>
          <Paper className={classes.printerpaper} ref={componentRef}>
            <Container className={classes.pageBreak}>
              {store.ReceiptFormatType === 1 && (
                <ReceiptFormat1 {...printingProps} />
              )}
              {store.ReceiptFormatType === 2 && (
                <ReceiptFormat2 {...printingProps} />
              )}
            </Container>
          </Paper>
        </div>
      )}
    </>
  );
};

export default PrintReceipt;
