import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import BackspaceIcon from "@material-ui/icons/Backspace";
import PrintIcon from "@material-ui/icons/Print";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { remove } from "../../utils/IdMaker";
import HiddenReceipt from "../FinishTransaction/HiddenReceipt";
import useEventListener from "../eventHook/EventListnerHook";

import KeyPadButton from "../controls/keyPadButton";
import KOTPrint from "../printing/kotPrint";
import { storeVoidOrder } from "../../api/service";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Constants from "../../utils/Constants";
import { makeStyles } from "@material-ui/core/styles";

const AddQtyAndDisscount = (props) => {
  const useStyles = makeStyles((theme) => ({
    selected: {
      "&&, &.Mui-selected:hover": {
        backgroundColor: "rgb(35, 54, 148)",
        color: "white",
      },
    },
  }));

  const classes = useStyles();

  const dispatch = useDispatch();
  const {
    store,
    cart,
    token,
    discount,
    adhocDiscountPer,
    discountPercent,
    selectedTableId,
  } = useSelector((state) => {
    return {
      store: state.store,
      menu: state.menu,
      cart: state.cartItems,
      FirstName: state.FirstName,
      token: state.token,
      discount: state.discount ?? 0,
      adhocDiscountPer: state.adhocDiscountPer ?? 0,
      discountPercent: state.discountPercent,
      selectedTableId: state.selectedTableId,
    };
  });

  // const { selectedTableId, cart } = useSelector((state) => {
  //   return {
  //     selectedTableId: state.selectedTableId,
  //     cart: state.cartItems,
  //   };
  // });

  const { getnumber, num, getNumberFromEvent, Total, handleNumber } = props;
  const [open, setOpen] = React.useState(false);
  const [checkCondition, setCondition] = React.useState("");
  const [enterPress, setEnterPress] = React.useState(false);
  const [name, setName] = React.useState("");
  const [printReceipt, setprintReceipt] = useState(false);
  const [printDineInKOT, setPrintDineInKOT] = useState(false);
  const [isPrePaymentInvoice, setIsPrePaymentInvoice] = useState(false);

  const handleClickOpen = (condition) => {
    // no action if cart is already empty.
    if (condition === "remove" && cart.cartItems.length === 0) return;

    if (condition !== "remove" && selectedTableId > -1) holdTransaction();
    else {
      setCondition(condition);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeTransaction = async () => {
    if (cart.orderType === 3) {
      let dateTime = new Date();

      let amount = 0.0;
      let qty = 0;

      cart.cartItems.map((x) => {
        amount = amount + x.ItemTotal;
        qty = qty + x.Count;

        if (x.Answers.length > 0) {
          x.Answers.map((y) => {
            // multiple the answer price with quantity of the item
            if (y.Price) amount = amount + Number(y.Price) * x.Count;
          });
        }
      });

      let tax = Math.round((amount * store.TaxPercent) / 100);

      const TotalAmount =
        amount - ((amount / 100) * adhocDiscountPer + discount) + tax;

      // setTaxAmount(tax);
      // setTotal(amount);
      // setQuantity(qty);

      if (cart.cartItems.length > 0) {
        const orderItems = cart.cartItems.map((item) => ({
          MenuItemId: item.MenuItemId,
          ActualPrice: item.Price,
          Quantity: item.Count,
          TotalPrice: item.ItemTotal,
          FinalPrice: item.ItemTotal,
          Answers: item.Answers,
          offerAmount: item.discount,
          offerPercent: item.discountPercent,
          adhocDiscountPer: item.adhocDiscountPer,
          adhocDiscountAmount: item.disc,
        }));

        const storeOrder = {
          TransID: cart.TransID,
          OrderNumber: cart.TransID,
          storeId: store.StoreId,
          OrderType: cart.orderType,
          OrderSource: 2,
          total: amount,
          tax: tax,
          discountPercent: discountPercent,
          discountAmount: (amount / 100) * adhocDiscountPer + discount,
          adhocDiscountPer: adhocDiscountPer,
          adhocDiscountAmount: (amount / 100) * adhocDiscountPer,
          //discountAmount: (total / 100) * adhocDiscountPer + discount,
          netPayable: TotalAmount,
          orderItem: orderItems,
          remarks: "",
          date: dateTime,
          deliveryAddress: null,
          paymentMode: cart.paymentMode,
        };

        console.log("Cancelling order: ", storeOrder);

        await storeVoidOrder(storeOrder, token)
          .then((res) => {
            console.log("Dine In Order cancelled", res);
          })
          .catch((e) => console.log(e));
      }
    }
    dispatch({ type: "CANCELTRANSACTION" });
    handleClose();
    if (cart.orderType != 3) {
      remove();
    }
  };

  const holdTransaction = () => {
    if (selectedTableId > -1) {
      setPrintDineInKOT(true);
      dispatch({ type: "HOLDTRANSACTION", payload: "" });
      handleClose();
    } else if (name !== "") {
      dispatch({ type: "HOLDTRANSACTION", payload: name });
      handleClose();
      setName("");
    }
  };

  const gotToFinishTransaction = () => {
    dispatch({ type: "DISPLAYTYPE", payload: "FinishTransaction" });
  };

  const handleEnterPress = () => setEnterPress(false);

  const handleDirectCashOut = () => {
    setEnterPress(true);
    if (num === "") {
      setEnterPress(true);
      setprintReceipt(true);
    } else if (Number(num) >= Number(Total)) {
      setEnterPress(true);
      setprintReceipt(true);
    } else {
      handleClickOpen("change");
    }
  };

  const whenPrintingIsDone = () => {
    setprintReceipt(false);
    setPrintDineInKOT(false);
    setIsPrePaymentInvoice(false);
  };

  const keyPressHandler = useCallback((key) => {
    if (
      key.code.includes("Digit") ||
      (key.code.includes("Numpad") && key.code !== "NumpadEnter")
    ) {
      getnumber(key.key);
    }
  });

  useEventListener("keypress", keyPressHandler);

  const handleEnter = () => {
    if (num != "") {
      if (String(num).includes("%")) {
        dispatch({
          type: "SLIPDISCOUNT",
          payload: Math.abs(Number(String(num.split("%")[0]))),
        });
        getnumber("");
      } else {
        dispatch({
          type: "SEARCHITEMCODE",
          payload: num,
        });
        getnumber("");
      }
    } else {
      if (enterPress === false) handleDirectCashOut();
    }
  };

  const handlePrePaymentInvoiceClick = () => {
    setIsPrePaymentInvoice((current) => {
      return true;
    });
  };

  const keyPressDownHandler = useCallback((key) => {
    if (key.target.name === "priceManual") {
      return;
    }

    if (String(num).length > 0) {
      if (key.key === "Backspace") {
        getnumber("remove");
      } else if (key.key === "Enter" || key.code === "NumpadEnter") {
        handleEnter();
      }
    }
  });

  const onPaymentModeChange = (event, selectedMode) => {
    dispatch({
      type: "CHANGEPAYMENTMODE",
      payload: selectedMode,
    });
  };

  useEventListener("keydown", keyPressDownHandler);

  return (
    <>
      <Paper
        style={{
          padding: "10px 20px 3px 20px",
          textAlign: "center",
        }}
      >
        <HiddenReceipt
          print={printReceipt}
          printingDone={whenPrintingIsDone}
          number={num}
          handleNumber={handleNumber}
          handleEnterPress={handleEnterPress}
          isPrePaymentInvoice={isPrePaymentInvoice}
        />

        <KOTPrint print={printDineInKOT} printingDone={whenPrintingIsDone} />

        <Grid container>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              onChange={(e) => getNumberFromEvent(e.target.value)}
              value={num}
              type="text"
              InputProps={{
                inputProps: { style: { color: "black", fontSize: "20px" } },
              }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignContent="left"
          style={{ marginTop: 10, height: "35px" }}
          exclusive
        >
          <Grid item xs={12}>
            <ToggleButtonGroup
              value={cart.paymentMode}
              onChange={onPaymentModeChange}
              exclusive
              sx={{ bgcolor: "blue" }}
              style={{ width: "100%" }}
              classes={{ selected: classes.selected }}
            >
              <ToggleButton
                value={Constants.PaymentMode.Cash}
                style={{ width: "50%", height: "35px" }}
                aria-label="left aligned"
                size="medium"
                classes={{ selected: classes.selected }}
              >
                <p>Cash</p>
              </ToggleButton>
              <ToggleButton
                value={Constants.PaymentMode.CreditCard}
                style={{ width: "50%", height: "35px" }}
                aria-label="right aligned"
                size="medium"
                classes={{ selected: classes.selected }}
              >
                <p>Credit Card</p>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{ marginTop: 20, marginBottom: 10 }}
        >
          <Grid key="00" item xs={2}>
            <KeyPadButton onClick={() => getnumber("7")} value="7" />
          </Grid>
          <Grid key="01" item xs={2}>
            <KeyPadButton onClick={() => getnumber("8")} value="8" />
          </Grid>
          <Grid key="02" item xs={2}>
            <KeyPadButton onClick={() => getnumber("9")} value="9" />
          </Grid>
          <Grid key="03" item xs={3}>
            <KeyPadButton onClick={() => getnumber("*")} value="x" />
          </Grid>
          <Grid key="04" item xs={3}>
            <Paper
              style={{ height: "100%", cursor: "pointer" }}
              onClick={() => handleClickOpen("hold")}
            >
              <ArrowDownwardIcon style={{ fontSize: 16 }} />
            </Paper>
          </Grid>
          <Grid key="10" item xs={2}>
            <KeyPadButton onClick={() => getnumber("4")} value="4" />
          </Grid>
          <Grid key="11" item xs={2}>
            <KeyPadButton onClick={() => getnumber("5")} value="5" />
          </Grid>
          <Grid key="12" item xs={2}>
            <KeyPadButton onClick={() => getnumber("6")} value="6" />
          </Grid>
          <Grid key="13" item xs={3}>
            <KeyPadButton onClick={() => getnumber("%")} value="%" />
          </Grid>
          <Grid key="14" item xs={3}>
            <Paper
              style={{ height: "100%", cursor: "pointer" }}
              onClick={() => handleClickOpen("remove")}
            >
              <DeleteIcon style={{ fontSize: 16 }} />
            </Paper>
          </Grid>
          <Grid key="20" item xs={2}>
            <KeyPadButton onClick={() => getnumber("1")} value="1" />
          </Grid>
          <Grid key="21" item xs={2}>
            <KeyPadButton onClick={() => getnumber("2")} value="2" />
          </Grid>
          <Grid key="22" item xs={2}>
            <KeyPadButton onClick={() => getnumber("3")} value="3" />
          </Grid>
          <Grid key="23" item xs={3}>
            <KeyPadButton onClick={() => getnumber("-")} value="-" />
          </Grid>
          <Grid key="24" item xs={3}>
            <Paper
              onClick={handleDirectCashOut}
              style={{ paddingTop: 2, paddingBottom: 2, cursor: "pointer" }}
            >
              <Typography
                style={{ fontSize: 12, fontWeight: "bold" }}
                component="p"
              >
                Fast Tender
              </Typography>
            </Paper>
          </Grid>
          <Grid key="30" item xs={2}>
            <KeyPadButton onClick={() => getnumber("0")} value="0" />
          </Grid>
          <Grid key="31" item xs={2}>
            <KeyPadButton onClick={() => getnumber(".")} value="." />
          </Grid>
          <Grid key="32" item xs={2}>
            <Paper
              style={{ height: "100%", cursor: "pointer" }}
              onClick={() => String(num).length > 0 && getnumber("remove")}
            >
              <BackspaceIcon style={{ fontSize: 14 }} />
            </Paper>
          </Grid>
          <Grid key="33" item xs={3}>
            <Paper
              style={{ height: "100%", cursor: "pointer" }}
              onClick={handleEnter}
            >
              <Typography
                style={{ fontSize: 14, fontWeight: "bold" }}
                component="p"
              >
                Enter
              </Typography>
            </Paper>
          </Grid>
          <Grid key="34" item xs={3}>
            <Paper
              style={{
                height: "100%",
                padding: 2,

                cursor: "pointer",
              }}
              onClick={handlePrePaymentInvoiceClick}
            >
              <PrintIcon style={{ fontSize: 16 }} />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <DialogForConformation
        open={open}
        handleClose={handleClose}
        checkCondition={checkCondition}
        removeTransaction={removeTransaction}
        holdTransaction={holdTransaction}
        name={name}
        setName={setName}
      />
    </>
  );
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "red",
    backgroundColor: "red",
  },
  toggleSelectedButton: {
    color: "red",
    backgroundColor: "red",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogForConformation = (props) => {
  const {
    open,
    handleClose,
    checkCondition,
    removeTransaction,
    holdTransaction,
    name,
    setName,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {checkCondition === "remove" ? (
        <>
          <DialogTitle id="alert-dialog-title" onClose={handleClose}>
            Are You Sure?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to delete the current receipt?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              no
            </Button>
            <Button onClick={removeTransaction} color="secondary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </>
      ) : checkCondition === "change" ? (
        <>
          <DialogTitle id="alert-dialog-title" onClose={handleClose}>
            Alert
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The amount should be greater than bill amount
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="secondary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle id="alert-dialog-title" onClose={handleClose}>
            Are You Sure?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Enter Customer Name
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Enter Customer Name"
              type="name"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              no
            </Button>
            <Button onClick={holdTransaction} color="secondary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default AddQtyAndDisscount;
