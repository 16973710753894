import React, { useState, useEffect } from "react";
import "./forcedQuestion.css";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  TextField,
  Input,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const ManualPriceEntry = (props) => {
  const { onAddToCart, onHandleClose, itemToAdd, handleNumber } = props;
  const [price, setPrice] = useState();
  const [isPriceValid, setIsPriceValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const validate = () => {
    if (price == undefined || price == "" || price < 1) {
      setIsPriceValid(false);
      setErrorMessage("Please enter valide price.");
      return false;
    } else {
      setIsPriceValid(true);
      setErrorMessage("");
    }
    return true;
  };

  const handleSubmit = () => {
    if (validate() == false) {
      return false;
    }
    itemToAdd.Price = Number(price);
    onAddToCart(itemToAdd);
    onHandleClose();
  };

  return (
    <Dialog open={true}>
      <DialogTitle id="form-dialog-title">
        Add To Cart
        <IconButton
          aria-label="close"
          className="close_action"
          onClick={onHandleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container alignContent="stretch" alignItems="stretch">
          <TextField
            name="priceManual"
            id="priceManual"
            variant="outlined"
            fullWidth
            onKeyPress={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
            onChange={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                e.preventDefault();
                handleSubmit();
              } else {
                handleNumber("");
                setPrice(e.target.value);
              }
            }}
            type="number"
            label="Enter Price"
            error={!isPriceValid}
            helperText={errorMessage}
            autoFocus
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className="button-block">
          <div>
            <Button
              onClick={onHandleClose}
              variant="contained"
              color="secondary"
              className="OrderNow"
              style={{ marginRight: 5, cursor: "pointer" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className="OrderNow"
            >
              Submit
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};
export default ManualPriceEntry;
