import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import MainPage from "./Components/MainPage/MainPage";
import OrderList from "./Components/OrderList/OrderList";

import { useNetwork } from "./Components/NetworkHook/useNetwork";
import PrivateRoute from "./Components/PrivateRoute/privateRoute";
import { placeOrder, storeVoidOrder } from "./api/service";
import Login from "./Components/Login/Login";
import { useSelector } from "react-redux";

const App = () => {
  const isOnline = useNetwork();
  const { token } = useSelector((state) => {
    return {
      token: state.token,
    };
  });

  const sendOrdersWhenOnline = async () => {
    console.log("sending offline orders to server");

    let localTransaction = localStorage.getItem("transactions");

    if (localTransaction !== null) {
      localTransaction = JSON.parse(localTransaction);

      var failedTransactions = [];

      for (let i = 0; i < localTransaction.length; i++) {
        const data = localTransaction[i];
        var orderCreated = await placeOrder(data, token, true);

        if (orderCreated.OrderId === -1) {
          failedTransactions.push(data);
        }
      }

      localStorage.setItem("transactions", JSON.stringify(failedTransactions));
    }

    console.log("Sending Offline void transactions");
    let voidTransactions = localStorage.getItem("voidTransactions");

    if (voidTransactions !== null) {
      voidTransactions = JSON.parse(voidTransactions);

      var failedVoidTransactions = [];

      for (let i = 0; i < voidTransactions.length; i++) {
        const data = voidTransactions[i];
        var orderCreated = await storeVoidOrder(data, token, true);
        console.log("orderCreated", orderCreated);

        if (orderCreated.OrderId === -1) {
          failedVoidTransactions.push(data);
        }
      }

      localStorage.setItem(
        "voidTransactions",
        JSON.stringify(failedVoidTransactions)
      );
    }
  };

  useEffect(() => {
    if (isOnline) sendOrdersWhenOnline();
  }, [isOnline]);

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/menu" component={MainPage} />
        <PrivateRoute exact path="/orderlist" component={OrderList} />
        <PrivateRoute exact path="/" component={MainPage} />
        <Route exact path="/login" component={Login} />
        <Route path="*" component={() => <h1>404 Not Found</h1>} />
      </Switch>
    </Router>
  );
};

export default App;
