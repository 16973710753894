import React, { useEffect, useState } from "react";

import { getMenuItems } from "../../api/service";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Paper } from "@material-ui/core";
import MainCategory from "./MainCategory";
import SubCategory from "./SubCategory";

const Menu = (props) => {
  const [payment, setPayment] = useState(false);
  const { handleNumber } = props;
  const { store, menu, token } = useSelector((state) => {
    return {
      store: state.store,
      menu: state.menu,
      token: state.token,
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      if (store.StoreId) {
        const Menu = await getMenuItems(store.StoreId, token);
        dispatch({ type: "GETMENU", payload: Menu });
      }
    };
    fetchData();
  }, [store.StoreId]);
  const onPaymentPress = () => {
    setPayment(!payment);
  };

  return (
    <>
      {menu ? (
        menu.length > 0 ? (
          <>
            <Paper
              style={{
                height: "20vh",
                overflowY: menu.length > 6 ? "scroll" : "hidden",
                overflowX: "hidden",
                marginTop: 20,
                marginLeft: 10,
                paddingLeft: 10,
              }}
            >
              <MainCategory />
            </Paper>

            <Paper
              style={{
                height: "50vh",
                overflowY: "scroll",
                overflowX: "hidden",
                marginTop: 10,
                marginBottom: 10,
                marginLeft: 10,
                paddingLeft: 10,
              }}
            >
              <SubCategory handleNumber={handleNumber} />
            </Paper>
          </>
        ) : (
          <div
            style={{
              height: "70vh",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h1>No active menus found</h1>
          </div>
        )
      ) : (
        <div
          style={{
            height: "70vh",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default Menu;
