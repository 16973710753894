import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { LazyBackground } from "../lazyLoading/lazyLoading";
import { transId } from "../../utils/IdMaker";
import Answer from "./answer";
import ForcedQuestion from "./forcedQuestionNew";
import ManualPriceEntry from "./manualPriceEntry";
import { formatPrice } from "../../helper";
import { kotGenerator } from "../../utils/kotNumMaker";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

const SubCategory = (props) => {
  const dispatch = useDispatch();
  const { handleNumber } = props;
  const [showQuestionPopup, setShowQuestionPopup] = useState();
  const [showPriceManualPopup, setShowPriceManualPopup] = useState();
  const [itemToAdd, setItemToAdd] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(
    "More than one item with this Item Code found."
  );
  const {
    menu,
    selectedCategory,
    cart,
    checkItemDiscount,
    discount,
    searchItem,
  } = useSelector((state) => {
    return {
      menu: state.menu,
      selectedCategory: state.selectedCategory,
      cart: state.cartItems,
      checkItemDiscount: state.checkItemDiscount,
      discount: state.discount,
      searchItem: state.searchItem,
    };
  });

  const handleDiscountStore = (check) => {
    if (check.OfferAmount <= 0 && check.OfferPercent <= 0) {
      return;
    } else {
      let isDuplicate = false;
      let checkDiscountArray = checkItemDiscount ? checkItemDiscount : [];
      checkDiscountArray.map((item) => {
        if (item.MenuItemId === check.MenuItemId) isDuplicate = true;
        return null;
      });
      if (isDuplicate === false) {
        checkDiscountArray.push(check);
        checkDiscountArray = [...new Set(checkDiscountArray)];
        dispatch({ type: "ITEMDISCOUNTEDITEMS", payload: checkDiscountArray });
      }
    }
  };

  const addItemDiscount = (item) => {
    //if (item.Price > item.OfferMinimumAmount && item.OfferAmount > 0) {
    if (item.OfferAmount > 0) {
      item.discount = item.OfferAmount;
      // dispatch({ type: "ITEMDISCOUNT", payload: discount + item.OfferAmount });
      // setDiscount(discount + item.OfferAmount)
      //} else if (item.Price > item.OfferMinimumAmount && item.OfferPercent > 0) {
    } else if (item.OfferPercent > 0) {
      item.discountPercent = item.OfferPercent;
    }
  };

  const newMenu = menu.filter(
    (category) => category.CategoryName === selectedCategory
  );

  const addToCartCliked = (item) => {
    handleDiscountStore(item);

    if (item.IsAllowManualEntry) {
      setShowPriceManualPopup(true);
      setItemToAdd({ ...item });
    } else if (item.Questions.length > 0) {
      let itemAnswers = [];

      item.Questions.forEach((q) => {
        itemAnswers.push(new Answer(q.Question, q.ShortName, ""));
      });
      // Send answers assuming quantity one
      setShowQuestionPopup(!showQuestionPopup);
      setItemToAdd({ ...item, Answers: itemAnswers });
    } else {
      addToCart(item);
    }
  };

  const handleQuestionPopupClose = () => {
    setShowQuestionPopup(!showQuestionPopup);
    setItemToAdd({});
  };

  const handleQuestionPopupSubmit = (itemToAdd) => {
    setShowQuestionPopup(!showQuestionPopup);
    setItemToAdd({});

    // FQ item will always we added as a new item
    addCartWithAnswer(itemToAdd);
  };

  const handleManualPricePopupClose = () => {
    setShowPriceManualPopup(!showPriceManualPopup);
    setItemToAdd({});
  };

  const handleManualPricePopupSubmit = (itemToAdd) => {
    setShowPriceManualPopup(!showPriceManualPopup);
    setItemToAdd({});
    addCartWithAnswer(itemToAdd);
    handleNumber("");
  };

  const doesItemKitchenAlreadyAdded = (cartItems, kitchenId) => {
    return cartItems.findIndex((x) => x.MenuCategoryId === kitchenId) > -1;
  };

  const addCartWithAnswer = (item) => {
    let cartItemsArray = cart.cartItems;

    if (!doesItemKitchenAlreadyAdded(cartItemsArray, item.MenuCategoryId))
      kotGenerator.generateNextNumber(item.MenuCategoryId);

    addItemDiscount(item);
    cartItemsArray.map((x) => {
      x.selected = false;
      return x;
    });

    let matchedIndex = -1;
    cartItemsArray.forEach((i, itemIndex) => {
      if (i.MenuItemId === item.MenuItemId) {
        let match = true;
        if (i.Questions.length > 0) {
          i.Answers.forEach((answer, index) => {
            if (
              answer.answer !== item.Answers[index].answer &&
              answer.question === item.Answers[index].question
            ) {
              match = false;
            }
          });
          if (match === true) {
            matchedIndex = itemIndex;
          }
        }
      }
    });

    if (matchedIndex > -1) {
      cartItemsArray[matchedIndex].Count =
        cartItemsArray[matchedIndex].Count + 1;
      cartItemsArray[matchedIndex].ItemTotal =
        item.Price * cartItemsArray[matchedIndex].Count;
    } else {
      cartItemsArray.push({
        ...item,
        Count: 1,
        ItemTotal: item.Price,
        selected: true,
        Answers: item.Answers ? item.Answers : [],
        adhocDiscountPer: 0,
      });
    }

    dispatchItemToCart(cartItemsArray);
  };

  const addToCart = (item) => {
    let alreadyExists = false;
    let cartItemsArray = cart.cartItems;

    if (!doesItemKitchenAlreadyAdded(cartItemsArray, item.MenuCategoryId))
      kotGenerator.generateNextNumber(item.MenuCategoryId);

    addItemDiscount(item);
    if (cartItemsArray.length > 0) {
      cartItemsArray.forEach((x) => {
        if (x.MenuItemId === item.MenuItemId) {
          alreadyExists = true;
          x.Count++;
          x.ItemTotal = x.Price * x.Count;
          x.selected = true;
        } else {
          x.selected = false;
        }
      });
    }

    if (!alreadyExists) {
      cartItemsArray.map((x) => {
        x.selected = false;
        return x;
      });

      cartItemsArray.push({
        ...item,
        Count: 1,
        ItemTotal: item.Price,
        selected: true,
        Answers: item.Answers ? item.Answers : [],
        adhocDiscountPer: 0,
      });
    }
    dispatchItemToCart(cartItemsArray);
  };

  const dispatchItemToCart = (cartItemsArray) => {
    let transID = cart.TransID;
    if (transID === 0) {
      transID = transId.next().value;
    }

    let newCart = {
      TransID: transID,
      orderType: cart.orderType,
      cartItems: cartItemsArray,
      customerInfo: cart.customerInfo,
      paymentMode: cart.paymentMode,
    };

    dispatch({ type: "ADDTOCART", payload: newCart });
  };

  useEffect(() => {
    if (searchItem != undefined && searchItem?.length === 0) {
      setShowAlert(true);
      setAlertMessage("No item found");
    } else if (searchItem != undefined && searchItem?.length > 1) {
      setShowAlert(true);
      setAlertMessage("More than one item with this Item Code found.");
    } else if (searchItem != undefined && searchItem?.length > 0) {
      console.log("calling addToCartCliked");
      addToCartCliked(searchItem[0]);
    }
    dispatch({
      type: "REMOVESEARCHITEMCODE",
    });
  }, [searchItem]);

  return (
    <>
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={() => {
          setShowAlert(false);
        }}
        sx={{ textAlign: "left", alignItems: "left" }}
      >
        <Alert
          serverity={"error"}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <div
        style={{
          textAlign: "center",
          //marginTop: 20,
          justifySelf: "center",
          padding: 10,
        }}
      >
        <Grid container spacing={2} alignContent="stretch" alignItems="stretch">
          {newMenu.length > 0 &&
            newMenu[0].MenuItems.map((data) => {
              return (
                <Grid item xs={6} sm={3} md={2} key={data.MenuItemId}>
                  {data.ImagePath === "" ? (
                    <Paper
                      style={{
                        border: "1px solid gray",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        alignContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => addToCartCliked(data)}
                    >
                      <div
                        style={{
                          height: 100,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography style={{ fontSize: 12 }}>
                          {data.Name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize:
                              data.OfferAmount && data.OfferAmount > 0
                                ? //   &&
                                  // data.Price > data.OfferMinimumAmount
                                  10
                                : data.OfferPercent && data.OfferPercent > 0
                                ? // &&
                                  // data.Price > data.OfferMinimumAmount
                                  10
                                : 12,
                            textDecoration:
                              data.OfferAmount &&
                              data.OfferAmount > 0 &&
                              data.IsAllowManualEntry == false
                                ? //   &&
                                  // data.Price > data.OfferMinimumAmount
                                  "line-through"
                                : data.OfferPercent && data.OfferPercent > 0
                                ? // &&
                                  // data.Price > data.OfferMinimumAmount
                                  "line-through"
                                : "none",
                          }}
                        >
                          {data.Currency} {formatPrice(data.Price)}
                        </Typography>
                        <Typography style={{ fontSize: 12 }}>
                          {data.OfferAmount && data.OfferAmount > 0
                            ? //   &&
                              // data.Price > data.OfferMinimumAmount
                              ` ${data.Currency} ${formatPrice(
                                data.Price - data.OfferAmount
                              )}`
                            : null}
                          {data.OfferPercent && data.OfferPercent > 0
                            ? //   &&
                              // data.Price > data.OfferMinimumAmount
                              ` ${data.Currency} ${formatPrice(
                                Math.round(
                                  data.Price -
                                    (data.Price / 100) * data.OfferPercent
                                )
                              )}`
                            : null}
                        </Typography>
                      </div>
                    </Paper>
                  ) : (
                    <Paper
                      style={{
                        border: "1px solid gray",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => addToCartCliked(data)}
                    >
                      <LazyBackground
                        src={data.ImagePath}
                        className="card-img-top"
                        height={60}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography style={{ fontSize: 12 }}>
                          {data.Name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize:
                              data.OfferAmount &&
                              data.OfferAmount > 0 &&
                              data.Price > data.OfferMinimumAmount
                                ? 10
                                : data.OfferPercent && data.OfferPercent > 0
                                ? // &&
                                  // data.Price > data.OfferMinimumAmount
                                  10
                                : 12,
                            textDecoration:
                              data.OfferAmount &&
                              data.OfferAmount > 0 &&
                              data.IsAllowManualEntry == false
                                ? //   &&
                                  // data.Price > data.OfferMinimumAmount
                                  "line-through"
                                : data.OfferPercent && data.OfferPercent > 0
                                ? // &&
                                  // data.Price > data.OfferMinimumAmount
                                  "line-through"
                                : "none",
                          }}
                        >
                          {data.Currency} {formatPrice(data.Price)}
                        </Typography>
                        <Typography style={{ fontSize: 12 }}>
                          {data.OfferAmount && data.OfferAmount > 0
                            ? //     &&
                              // data.Price > data.OfferMinimumAmount
                              ` ${data.Currency} ${formatPrice(
                                data.Price - data.OfferAmount
                              )}`
                            : null}
                          {data.OfferPercent && data.OfferPercent > 0
                            ? //     &&
                              // data.Price > data.OfferMinimumAmount
                              ` ${data.Currency} ${formatPrice(
                                Math.round(
                                  data.Price -
                                    (data.Price / 100) * data.OfferPercent
                                )
                              )}`
                            : null}
                        </Typography>
                      </div>
                    </Paper>
                  )}
                </Grid>
              );
            })}
        </Grid>
        {showQuestionPopup && (
          <ForcedQuestion
            itemToAdd={itemToAdd}
            onAddToCart={handleQuestionPopupSubmit}
            onHandleClose={handleQuestionPopupClose}
          />
        )}
        {showPriceManualPopup && (
          <ManualPriceEntry
            itemToAdd={itemToAdd}
            onAddToCart={handleManualPricePopupSubmit}
            onHandleClose={handleManualPricePopupClose}
            handleNumber={handleNumber}
          />
        )}
      </div>
    </>
  );
};

export default SubCategory;
