import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Paper, Typography, Container, Button } from "@material-ui/core";
import { formatPrice } from "../../helper";
import { placeOrder } from "../../api/service";
import { useReactToPrint } from "react-to-print";
import ChangeDialoge from "./ChangeDialoge";

const DisplayRecipt = (props) => {
  const { number, handleNumber } = props;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const dispatch = useDispatch();
  const [total, setTotal] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [deliveryFee, setDeliveryFee] = React.useState(0);
  const [taxAmount, setTaxAmount] = React.useState(0);

  const { store, cart, FirstName, token, discount } = useSelector((state) => {
    return {
      store: state.store,
      cart: state.cartItems,
      FirstName: state.FirstName,
      token: state.token,
      discount: state.discount,
    };
  });

  React.useEffect(() => {
    let deliveryLocation = cart.customerInfo.DeliveryArea
      ? cart.customerInfo.DeliveryArea
      : "";
    if (cart.orderType === 0) {
      let charges = store.StoreDeliveryFees.filter(
        (data) => data.LocationName === deliveryLocation
      );
      console.log(charges);
      if (charges.length > 0) {
        setDeliveryFee(charges[0].DeliveryFees);
      }
    }
  });

  const date = new Date();
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getTotalAmount = () => {
    let amount = 0.0;
    cart.cartItems.map((x) => {
      amount = amount + x.ItemTotal;
      if (x.Answers.length > 0) {
        x.Answers.map((y) => {
          // multiple the answer price with quantity of the item
          if (y.Price) amount = amount + Number(y.Price) * x.Count;
        });
      }
    });

    let tax = Math.round((amount * store.TaxPercent) / 100);

    setTaxAmount(tax);
    setTotal(amount);
  };

  React.useEffect(() => {
    getTotalAmount();
  });

  const handleClose = () => {
    setOpen(false);
    handleNumber("");
    dispatch({ type: "CANCELTRANSACTION" });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const submitTransaction = async () => {
    let dateTime = new Date();
    let deliveryAddress = {
      customerName: cart.customerInfo.CustomerName,
      phoneNumber: cart.customerInfo.PhoneNumber,
      email: cart.customerInfo.Email,
      streetAddress: cart.customerInfo.StreetAddress,
      deliveryArea: cart.customerInfo.DeliveryArea,
      cityId: store.CityId,
      countryId: store.CountryId,
    };

    if (cart.cartItems.length > 0) {
      const orderItems = cart.cartItems.map((item) => ({
        MenuItemId: item.MenuItemId,
        ActualPrice: item.Price,
        Quantity: item.Count,
        TotalPrice: item.ItemTotal,
        FinalPrice: item.ItemTotal,
        Answers: item.Answers,
      }));

      const storeOrder = {
        TransID: cart.TransID,
        OrderNumber: cart.TransID,
        storeId: store.StoreId,
        OrderType: cart.orderType,
        OrderSource: 2,
        deliveryCharge: cart.orderType === 0 ? deliveryFee : 0,
        total: total,
        tax: taxAmount,
        discountPercent: 0,
        discountAmount: discount,
        netPayable: total,
        orderItem: orderItems,
        remarks: "",
        date: dateTime,
        deliveryAddress: cart.orderType === 0 ? deliveryAddress : null,
      };

      await handlePrint();

      console.log("storeOrder");
      console.log(storeOrder);
      await placeOrder(storeOrder, token)
        .then((res) => {
          // console.log(res);

          props.printingDone();
        })
        .catch((e) => console.log(e));
      if (number > TotalAmount) {
        handleOpen();
      } else {
        handleNumber("");
        dispatch({ type: "CANCELTRANSACTION" });
      }
    }
  };

  const handleSubmitTransaction = () => {
    if (number >= TotalAmount) {
      submitTransaction();
    } else {
      alert("The amount should be greater than bill amount");
    }
  };

  const TotalAmount =
    cart.orderType === 0
      ? deliveryFee + total - discount + taxAmount
      : total - discount + taxAmount;

  const orderType = ["Delivery", "TakeAway", "Drive-Thru", "DineIn"];

  return (
    <>
      <Paper style={{ width: "100%", height: 100, marginBottom: 10 }}></Paper>
      <Paper style={{ width: "100%", height: "74%" }} ref={componentRef}>
        <Container>
          <Grid container>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <div
                style={{
                  textAlign: "center",
                  width: "80%",
                  marginLeft: "10%",
                  marginTop: 20,
                }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {store.Name + "*** waseem"}
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  {store.StreetAddress}
                </Typography>
                <br />
                <Typography style={{ fontSize: 12 }}>
                  Tax Registration # {store.TaxRegistrationNumber}
                </Typography>
                <br />
                <Typography style={{ fontSize: 12 }}>
                  Invoice Number : {cart.TransID}
                </Typography>
                <Typography style={{ fontSize: 11, fontWeight: "bold" }}>
                  {console.log(cart.orderType)}
                  {orderType[Number(cart.orderType)]?.toUpperCase()}
                </Typography>
                <br />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontSize: 12 }}>
                {`${
                  date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
                }-${month[date.getMonth()]}-${date.getFullYear()}`}{" "}
                {`${date.getHours()}:${
                  date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes()
                }`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ textAlign: "right", fontSize: 12 }}>
                employee: {FirstName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            {cart.cartItems.map((data) => (
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={{ textAlign: "Left", fontSize: 12 }}>
                    {data.Count} x {data.Name}
                  </Typography>
                  <Typography style={{ textAlign: "right", fontSize: 12 }}>
                    {formatPrice(data.ItemTotal)}
                  </Typography>
                </div>
                {data.Answers
                  ? data.Answers.map((a, i) => {
                      return a.Price ? (
                        <Grid
                          container
                          alignContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={8}>
                            <Typography
                              style={{ fontSize: 11, marginLeft: 20 }}
                            >
                              {a.answer}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              style={{ fontSize: 11, textAlign: "right" }}
                            >
                              {formatPrice(a.Price)}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          alignContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={8}>
                            <Typography
                              style={{ fontSize: 11, marginLeft: 20 }}
                            >
                              {a.answer}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })
                  : null}
              </Grid>
            ))}
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ textAlign: "right", fontSize: 12 }}>
                Sub Total:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ textAlign: "right", fontSize: 12 }}>
                {store.Currency} {formatPrice(total)}
              </Typography>
            </Grid>
            {discount !== 0 && (
              <>
                <Grid item xs={8}>
                  <Typography style={{ textAlign: "right", fontSize: 12 }}>
                    Discount:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ textAlign: "right", fontSize: 12 }}>
                    {store.Currency} -{formatPrice(discount)}
                  </Typography>
                </Grid>
              </>
            )}
            {cart.orderType === 0 ? (
              <>
                <Grid item xs={8}>
                  <Typography style={{ textAlign: "right", fontSize: 12 }}>
                    Delivery Fee:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ textAlign: "right", fontSize: 12 }}>
                    {store.Currency} {formatPrice(deliveryFee)}
                  </Typography>
                </Grid>
              </>
            ) : null}
            {taxAmount > 0 && (
              <Typography style={{ textAlign: "right", fontSize: 12 }}>
                Tax {formatPrice(taxAmount)}
              </Typography>
            )}
            <Grid item xs={8}>
              <Typography style={{ textAlign: "right", fontSize: 12 }}>
                Total:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ textAlign: "right", fontSize: 12 }}>
                {store.Currency} {formatPrice(TotalAmount)}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ textAlign: "right", fontSize: 12 }}>
                Customer Paid:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ textAlign: "right", fontSize: 12 }}>
                {store.Currency} {formatPrice(number)}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ textAlign: "right", fontSize: 12 }}>
                Return Amount
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ textAlign: "right", fontSize: 12 }}>
                {store.Currency} {formatPrice(TotalAmount - Number(number))}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  textAlign: "center",
                  width: "80%",
                  marginLeft: "10%",
                  marginTop: 20,
                }}
              >
                <Typography style={{ fontSize: 12 }}>
                  Thanks For Your Visit
                </Typography>
                <Typography style={{ fontSize: 12 }}>Come Back Soon</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <br />
              <br />
              <Typography style={{ fontSize: 9, textAlign: "center" }}>
                Powered by Eatachi
              </Typography>
              <Typography style={{ fontSize: 9, textAlign: "center" }}>
                www.eatachi.co
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <div style={{ marginTop: 10, padding: 20 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmitTransaction}
        >
          Finish Transaction
        </Button>
      </div>
      <ChangeDialoge
        open={open}
        handleClose={handleClose}
        number={number}
        TotalAmount={TotalAmount}
        Currency={store.Currency}
      />
    </>
  );
};

export default DisplayRecipt;
