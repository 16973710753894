import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";

export default function FormDialog(props) {
  const { open, handleCancel, handleSubmit } = props;

  const dispatch = useDispatch();

  const [customerName, setCustomerName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [streetAddress, setStreetAddress] = React.useState("");
  const [DeliveryArea, setDeliveryArea] = React.useState("");

  const { store } = useSelector((state) => {
    return {
      store: state.store,
    };
  });

  const clearAllFields = () => {
    setCustomerName("");
    setPhoneNumber("");
    setStreetAddress("");
    setEmail("");
    setDeliveryArea("");
  };

  const onSubmit = (evt) => {
    evt.preventDefault();

    if (customerName !== "" && phoneNumber !== "" && streetAddress !== "") {
      let data = {
        CustomerName: customerName,
        PhoneNumber: phoneNumber,
        Email: email,
        StreetAddress: streetAddress,
        DeliveryArea,
      };

      dispatch({ type: "ADDCUSTOMER", payload: data });

      // Make the just added customer the selected customer too.
      dispatch({ type: "SELECTCUSTOMER", payload: data });

      clearAllFields();
      handleSubmit();
    }
  };

  const handleClose = () => {
    clearAllFields();
    handleCancel();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Customer</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent="space-around"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="Name"
                label="Name"
                type="text"
                fullWidth
                required
                value={customerName}
                onKeyDown={(e) => e.stopPropagation()}
                onKeyPress={(e) => e.stopPropagation()}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="phonenumber"
                label="Phone Number"
                type="number"
                fullWidth
                required
                value={phoneNumber}
                onKeyDown={(e) => e.stopPropagation()}
                onKeyPress={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="email"
                label="Customer Email"
                type="email"
                fullWidth
                value={email}
                onKeyDown={(e) => e.stopPropagation()}
                onKeyPress={(e) => e.stopPropagation()}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel htmlFor="Delivery-Area">Delivery Area</InputLabel>
                <Select
                  native
                  required
                  fullWidth
                  value={DeliveryArea}
                  onKeyDown={(e) => e.stopPropagation()}
                  onKeyPress={(e) => e.stopPropagation()}
                  onChange={(e) => setDeliveryArea(e.target.value)}
                  inputProps={{
                    name: "Delivery Area",
                    id: "Delivery-Area",
                  }}
                  disabled={
                    store.StoreDeliveryFees &&
                    store.StoreDeliveryFees.length > 0
                      ? false
                      : true
                  }
                >
                  <option aria-label="None" value="" />
                  {store.StoreDeliveryFees && store.StoreDeliveryFees.length > 0
                    ? store.StoreDeliveryFees.map((data, index) => (
                        <option
                          value={data.LocationName}
                          key={"key-loc-name_" + index}
                        >
                          {data.LocationName}
                        </option>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="name"
                label="Address"
                type="text"
                required
                fullWidth
                value={streetAddress}
                onKeyDown={(e) => e.stopPropagation()}
                onKeyPress={(e) => e.stopPropagation()}
                onChange={(e) => setStreetAddress(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
