import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  withStyles,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { getCustomers } from "../../api/service";
import DataTable from "./DataTable";
import AddCustomerDialog from "./AddCustomerDialog";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const CustDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomerDialogBox = (props) => {
  const { open, handleClose } = props;

  const dispatch = useDispatch();
  const { store, customers, token } = useSelector((state) => {
    return {
      store: state.store,
      customers: state.customers,
      token: state.token,
    };
  });

  const [row, setRows] = React.useState([]);
  const [openAddCustomer, setOpenAddCustomer] = React.useState(false);
  const [filter, setFilter] = React.useState("");

  const [filterBy, setFilterBy] = React.useState("");

  useEffect(() => {
    // const fetchData = async () => {
    //   const Customer = await getCustomers(store.StoreId, token);
    //   dispatch({ type: "GETCUSTOMER", payload: Customer });
    //   setRows(Customer);
    // };
    fetchData();
  }, [store.StoreId]);

  const fetchData = async () => {
    if (store.StoreId) {
      const Customer = await getCustomers(store.StoreId, token);
      dispatch({ type: "GETCUSTOMER", payload: Customer });
      setRows(Customer);
    }
  };

  useEffect(() => {
    if (open) {
      fetchData();
      setRows(customers);
    }
  }, [open]);

  const handleClickOpenAddCustomer = () => {
    setOpenAddCustomer(true);
  };

  const handleAddCustomerSubmit = () => {
    setOpenAddCustomer(false);

    // Select the last added customer
    onSelectCustomer(customers.length - 1);
  };

  const handleAddCustomerCancel = () => {
    setOpenAddCustomer(false);
  };

  let excludeColumns = ["DeliveryAddressId"];

  const updateRows = (value) => {
    setFilter(value);
    filterData(value);
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();

    if (lowercasedValue === "") setRows(customers);
    else {
      const filteredData = customers.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key]?.toString().toLowerCase().includes(lowercasedValue)
        );
      });

      setRows(filteredData);
    }
  };

  const onSelectCustomer = (index) => {
    handleClose(index);
    setRows(customers);
    setFilter("");
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen
        maxWidth="lg"
        style={{ height: "100vh" }}
      >
        <CustDialogTitle id="alert-dialog-title" onClose={handleClose}>
          Customer List
        </CustDialogTitle>
        <DialogContent>
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="Name"
                label="Search"
                type="text"
                onChange={(e) => updateRows(e.target.value)}
                value={filter}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <DataTable rows={row} close={onSelectCustomer} filter={filter} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpenAddCustomer} color="secondary">
            Add New Customer
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <AddCustomerDialog
        open={openAddCustomer}
        handleCancel={handleAddCustomerCancel}
        handleSubmit={handleAddCustomerSubmit}
      />
    </>
  );
};

export default CustomerDialogBox;
