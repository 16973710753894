import Constants from "../utils/Constants";

const reducer = (state, action) => {
  let cartSummary = {};

  switch (action.type) {
    case "GETSTORE":
      return {
        ...state,
        store: action.payload,
      };
    case "BRANDDATA":
      return {
        ...state,
        ...action.payload,
        cartItems: {
          ...state.cartItems,
          orderType: getOrderType(action.payload.store),
          paymentMode: Constants.PaymentMode.Cash,
        },
      };
    case "LOGIN":
      return {
        ...state,
        isLogedIn: true,
        ...action.payload,
      };

    case "LOGOUT":
      return {
        ...state,
        isLogedIn: !state.isLogedIn,
        displayOption: "Menu",
        cartItems: {
          cartItems: [],
          customerInfo: {},
          TransID: 0,
          adhocDiscountPer: 0,
          discount: 0,
          netPayable: 0,
          orderType: 1, // TAKEAWAY
          subTotal: 0,
          taxAmount: 0,
          paymentMode: Constants.PaymentMode.Cash,
        },
        checkItemDiscount: [],
      };

    case "GETMENU":
      return {
        ...state,
        menu: action.payload,
        currentMenu: action.payload,
        selectedCategory:
          action.payload.length > 0 ? action.payload[0].CategoryName : "",
      };
    case "SEARCHITEMCODE":
      //const item = searchMenu(state.menu, action.payload);
      return {
        ...state,
        searchItem: searchMenu(state.menu, action.payload),
      };
    case "REMOVESEARCHITEMCODE":
      return {
        ...state,
        searchItem: null,
      };
    case "GETCUSTOMER":
      return {
        ...state,
        customers: action.payload,
      };
    case "SELECTEDCETEGORY":
      // console.log(action.payload);
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case "ITEMDISCOUNTEDITEMS":
      return {
        ...state,
        checkItemDiscount: action.payload,
      };
    case "ADDTOCART": {
      cartSummary = calculateCartSummary(
        action.payload.cartItems,
        action.payload.adhocDiscountPer,
        state.store.TaxPercent,
        state.store.Offer,
        state.checkItemDiscount
      );

      return {
        ...state,
        cartItems: {
          ...action.payload,
          ...cartSummary,
          adhocDiscountPer: 0,
        },
        discount: cartSummary.discount,
        discountPercent: cartSummary.discountPercent,
      };
    }

    case "INCREASECOUNT": {
      cartSummary = calculateCartSummary(
        action.payload.cartItems,
        action.payload.adhocDiscountPer,
        state.store.TaxPercent,
        state.store.Offer,
        state.checkItemDiscount
      );

      return {
        ...state,
        cartItems: {
          ...action.payload,
          ...cartSummary,
          adhocDiscountPer: 0,
        },
        discount: cartSummary.discount,
        discountPercent: cartSummary.discountPercent,
      };
    }
    case "DECREASECOUNT": {
      console.log("dec count: ", action.payload);

      let decrease = action.payload;
      decrease = decrease.cartItems.filter((value) => {
        if (value.Count !== 0) {
          return value;
        }
      });

      cartSummary = calculateCartSummary(
        decrease,
        state.cartItems.adhocDiscountPer,
        state.store.TaxPercent,
        state.store.Offer,
        state.checkItemDiscount
      );

      return {
        ...state,
        cartItems: {
          ...state.cartItems,
          cartItems: decrease.slice(), // create copy. IMPORTANT
          ...cartSummary,
          adhocDiscountPer: 0,
        },

        discount: cartSummary.discount,
        discountPercent: cartSummary.discountPercent,
      };
    }
    case "CANCELTRANSACTION": {
      let newTables;

      if (state.selectedTableId > -1) {
        newTables = state.tables.map((table) =>
          table.id === state.selectedTableId
            ? { ...table, available: true, orderDetail: {} }
            : table
        );
      } else newTables = state.tables.slice();

      return {
        ...state,
        cartItems: {
          TransID: 0,
          cartItems: [],
          orderType: getOrderType(state.store),
          customerInfo: [],
          subTotal: 0,
          discount: 0,
          adhocDiscountPer: 0,
          taxAmount: 0,
          netPayable: 0,
          paymentMode: Constants.PaymentMode.Cash,
        },
        displayOption: "Menu",
        discount: 0,
        adhocDiscountPer: 0,
        selectedTableId: -1, // order type is now default
        tables: newTables,
      };
    }
    case "HOLDTRANSACTION":
      let currentTransaction = state.cartItems;

      if (state.selectedTableId > -1) {
        const crntTableIndex = state.tables.findIndex(
          (table) => table.id === state.selectedTableId
        );

        if (crntTableIndex === -1) return { ...state };

        const orderDifferenceCart = getDifferenceInCartForKOT(
          state.tables[crntTableIndex].orderDetail,
          currentTransaction,
          state.selectedTableId
        );

        let updatedTables = assignCurrentCartToTable(
          state,
          currentTransaction,
          crntTableIndex
        );

        return {
          ...state,
          cartItems: {
            TransID: 0,
            cartItems: [],
            orderType: getOrderType(state.store),
            customerInfo: [],
            subTotal: 0,
            discount: 0,
            adhocDiscountPer: 0,
            taxAmount: 0,
            netPayable: 0,
            paymentMode: Constants.PaymentMode.Cash,
          },
          discount: 0,
          adhocDiscountPer: 0,
          taxAmount: 0,
          tables: updatedTables,
          selectedTableId: -1,
          kotCart: orderDifferenceCart,
        };
      } else {
        currentTransaction.name = action.payload;
        return {
          ...state,
          holdTransactions: [...state.holdTransactions, currentTransaction],
          cartItems: {
            TransID: 0,
            cartItems: [],
            orderType: getOrderType(state.store),
            customerInfo: [],
            subTotal: 0,
            discount: 0,
            adhocDiscountPer: 0,
            taxAmount: 0,
            netPayable: 0,
            paymentMode: Constants.PaymentMode.Cash,
          },
          discount: 0,
          adhocDiscountPer: 0,
        };
      }
    case "RETRIVETRANSACTION":
      return {
        ...state,
        cartItems: { ...action.payload.cart, isSavedOrder: true },
        holdTransactions: action.payload.hold,
        discount: action.payload.cart.discount,
        adhocDiscountPer: action.payload.cart.adhocDiscountPer ?? 0,
      };
    case "CONNECTION":
      return {
        ...state,
        isDisconected: action.payload,
      };
    case "ORDERS":
      return {
        ...state,
        OrderList: action.payload,
      };
    case "BACKTOMENU":
      return {
        ...state,
        displayOption: "Menu",
      };

    case "CARTITEMDISCOUNT":
      let updateItemDiscount = state.cartItems.cartItems;
      updateItemDiscount[action.payload.index] = action.payload.item;

      let newDiscount = 0;
      let prevDisc = updateItemDiscount[action.payload.index].disc;

      let disc =
        (updateItemDiscount[action.payload.index].Price / 100) *
          updateItemDiscount[action.payload.index].adhocDiscountPer ??
        0 * updateItemDiscount[action.payload.index].Count;

      updateItemDiscount[action.payload.index].Answers.map((ans) => {
        if (ans.Price) {
          disc =
            disc +
              (ans.Price / 100) *
                updateItemDiscount[action.payload.index].adhocDiscountPer ??
            0 * updateItemDiscount[action.payload.index].Count;
        }
        return null;
      });

      updateItemDiscount[action.payload.index].disc = disc;

      let updateCartItems = {
        ...state.cartItems,
        cartItems: updateItemDiscount,
      };

      if (
        prevDisc &&
        prevDisc > 0 &&
        updateItemDiscount[action.payload.index].adhocDiscountPer === 0
      ) {
        newDiscount = state.discount - prevDisc;
      } else {
        newDiscount = state.discount + disc;
      }

      return {
        ...state,
        cartItems: updateCartItems,
        discount: newDiscount,
      };
    case "CARTITEMQTY":
      let updateItemQty = state.cartItems.cartItems;
      updateItemQty[action.payload.index] = action.payload.item;
      const Item = {
        ...state.cartItems,
        cartItems: updateItemQty,
      };
      return {
        ...state,
        cartItems: Item,
        discount: action.payload.newDiscount,
      };

    case "ORDERTYPE":
      return {
        ...state,
        cartItems: {
          ...state.cartItems,
          orderType: action.payload,
          customerInfo:
            action.payload === 0 ? state.cartItems.customerInfo : [],
        },
        selectedTableId: action.payload === 2 ? state.selectedTableId : -1,
      };

    case "DISPLAYTYPE":
      return {
        ...state,
        displayOption: action.payload,
      };

    case "ADDCUSTOMER": {
      console.log("adding customer");

      return {
        ...state,
        customers: [...state.customers, action.payload],
      };
    }

    case "SELECTCUSTOMER":
      return {
        ...state,
        cartItems: {
          ...state.cartItems,
          customerInfo: action.payload,
        },
      };

    case "UPDATETOCART":
      cartSummary = calculateCartSummary(
        action.payload.cartItems,
        action.payload.adhocDiscountPer,
        state.store.TaxPercent,
        state.store.Offer,
        state.checkItemDiscount
      );

      return {
        ...state,
        cartItems: { ...action.payload, ...cartSummary },
      };

    case "ITEMDISCOUNT":
      return {
        ...state,
        discount: action.payload,
      };

    case "SLIPDISCOUNT":
      cartSummary = calculateCartSummary(
        state.cartItems.cartItems,
        action.payload,
        state.store.TaxPercent,
        state.store.Offer,
        state.checkItemDiscount
      );

      return {
        ...state,
        cartItems: {
          ...state.cartItems,
          adhocDiscountPer: action.payload,
          ...cartSummary,
        },
        adhocDiscountPer: action.payload,
      };

    case "REMOVEITEMFROMCART":
      let removeItemFromCart = state.cartItems.cartItems;
      removeItemFromCart.splice(action.payload, 1);

      cartSummary = calculateCartSummary(
        removeItemFromCart,
        state.cartItems.adhocDiscountPer,
        state.store.TaxPercent,
        state.store.Offer,
        state.checkItemDiscount
      );

      return {
        ...state,
        cartItems: {
          ...state.cartItems,
          cartItems: removeItemFromCart,
          ...cartSummary,
        },
      };

    case "SHOWTABLEPOPUP":
      return {
        ...state,
        showSelectTablePopup: true,
      };
    case "HIDETABLEPOPUP":
      return {
        ...state,
        showSelectTablePopup: false,
      };

    case "SELECTTABLE":
      // selected table is selected again.
      if (action.payload === state.selectedTableId) return { ...state };

      const newSelTableState = state.tables.find(
        (table) => table.id === action.payload
      );

      // if table is available and cart is not empty
      if (newSelTableState.available && state.cartItems.cartItems.length > 0) {
        // Switching bill to another table.
        if (state.selectedTableId !== -1) {
          const crntSelTableState = state.tables.find(
            (table) => table.id === state.selectedTableId
          );

          if (crntSelTableState) {
            const newTables = state.tables.map((table) => {
              if (table.id === newSelTableState.id)
                // transfer to new table
                return {
                  ...table,
                  available: false,
                  orderDetail: crntSelTableState.orderDetail,
                };
              else if (table.id === state.selectedTableId) {
                // clear out old table
                return {
                  ...table,
                  available: true,
                  orderDetail: {},
                };
              } else return table;
            });

            return {
              ...state,
              selectedTableId: action.payload,
              tables: newTables,
            };
          }
        } else
          return {
            ...state,
            cartItems: {
              ...state.cartItems,
              customerInfo: [],
              orderType: 3,
            },
            selectedTableId: action.payload,
          };
      }

      // if table is available and cart is empty
      if (
        newSelTableState.available &&
        state.cartItems.cartItems.length === 0
      ) {
        return {
          ...state,
          cartItems: {
            ...state.cartItems,
            orderType: 3,
          },
          selectedTableId: action.payload,
        };
      }

      // if table is unavailable and cart is not empty
      if (!newSelTableState.available && state.cartItems.cartItems.length > 0) {
        alert("Please save the existing order first.");

        return {
          ...state,
        };
      }

      const newTables = state.tables.map((table) =>
        table.id === action.payload ? { ...table, available: false } : table
      );

      return {
        ...state,
        cartItems: {
          TransID: newSelTableState.orderDetail.TransID,
          cartItems: newSelTableState.orderDetail.cartItems.map((item) => {
            return { ...item };
          }), // assign deep clone of the array

          // IMPORTANT otherwise cart order type will not be udpated.
          orderType: newSelTableState.orderDetail.orderType,
          customerInfo: newSelTableState.orderDetail.customerInfo?.slice(),

          adhocDiscountPer: newSelTableState.orderDetail.adhocDiscountPer,
          subTotal: newSelTableState.orderDetail.subTotal,
          discount: newSelTableState.orderDetail.discount,
          taxAmount: newSelTableState.orderDetail.taxAmount,
          netPayable: newSelTableState.orderDetail.netPayable,

          // if selected table already has a saved order
          isSavedOrder: newSelTableState.orderDetail.cartItems.length > 0,
        },
        discount: newSelTableState.orderDetail.discount,
        adhocDiscountPer: newSelTableState.orderDetail.adhocDiscountPer,
        selectedTableId: action.payload,
        tables: newTables,
      };

    case "CLEAR_KOT_CART": {
      return { ...state, kotCart: {} };
    }

    case "CHANGEPAYMENTMODE": {
      return {
        ...state,
        cartItems: {
          ...state.cartItems,
          paymentMode: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

const assignCurrentCartToTable = (
  state,
  currentTransaction,
  crntTableIndex
) => {
  let newTables = state.tables.slice();

  console.log({ currentTransaction });

  newTables[crntTableIndex] = {
    ...newTables[crntTableIndex],
    available: false,
    orderDetail: {
      TransID: currentTransaction.TransID,
      cartItems: currentTransaction.cartItems?.slice(), // assign copy of array
      orderType: currentTransaction.orderType,
      customerInfo: Array.isArray(currentTransaction.customerInfo)
        ? currentTransaction.customerInfo?.slice(0)
        : null,

      subTotal: currentTransaction.subTotal,
      discount: currentTransaction.discount,
      adhocDiscountPer: currentTransaction.adhocDiscountPer,
      taxAmount: currentTransaction.taxAmount,
      netPayable: currentTransaction.netPayable,
    },
  };

  return newTables;
};

const checkDiscountOffer = (subTotal, disc, cartItems, checkItemDiscount) => {
  if (disc.length > 0) {
    let Amount = subTotal;

    cartItems.map((cartItem) => {
      checkItemDiscount?.map((item) => {
        if (item.MenuItemId === cartItem.MenuItemId) {
          Amount -= cartItem.ItemTotal;
        }
      });
    });

    let filterOfferMinimum = disc.filter((y) => (!y.MinimumAmount ? null : y));
    let filterOffer = disc.filter((y) => (!y.MinimumAmount ? y : null));

    if (Amount <= 0) {
      return { amount: 0, percentage: 0 };
    }

    if (filterOffer.length > 0) {
      if (filterOffer && filterOffer[0].Amount) {
        return { amount: filterOffer[0].Amount, percentage: 0 };
      } else if (filterOffer && filterOffer[0].Percentage) {
        return {
          amount: (Amount / 100) * filterOffer[0].Percentage,
          percentage: filterOffer[0].Percentage,
        };
      } else {
        return { amount: 0, percentage: 0 };
      }
    } else if (filterOfferMinimum.length > 0) {
      let check = [];
      filterOfferMinimum.map((a) =>
        subTotal >= a.MinimumAmount ? check.push(true) : check.push(false)
      );
      let offer = filterOfferMinimum[check.filter(Boolean).length - 1];
      if (offer && offer.Amount) {
        return { amount: offer.Amount, percentage: 0 };
      } else if (offer && offer.Percentage) {
        return {
          amount: (Amount / 100) * offer.Percentage,
          percentage: offer.Percentage,
        };
      } else {
        return { amount: 0, percentage: 0 };
      }
    } else {
      return { amount: 0, percentage: 0 };
    }
  } else {
    return { amount: 0, percentage: 0 };
  }
};

const calculateCartSummary = (
  cartItems,
  adhocDiscountPer,
  taxPercent,
  storeOffers,
  checkItemDiscount
) => {
  let subTotal = 0,
    discount = 0,
    discountPercent = 0;

  cartItems.forEach((item) => {
    subTotal += item.ItemTotal;

    //if (item.Price > item.OfferMinimumAmount && item.OfferAmount > 0)
    if (item.OfferAmount > 0) discount += item.OfferAmount * item.Count;
    // else if (item.Price > item.OfferMinimumAmount && item.OfferPercent > 0) {
    else if (item.OfferPercent > 0) {
      discount +=
        Math.round((item.Price / 100) * item.OfferPercent) * item.Count;
      discountPercent = item.OfferPercent;
    }

    if (item.disc > 0) {
      discount += item.disc;
    }

    if (item.Answers.length > 0) {
      item.Answers.forEach((answer) => {
        // multiple the answer price with quantity of the item
        if (answer.Price) subTotal += Number(answer.Price) * item.Count;
      });
    }
  });

  if (adhocDiscountPer > 0)
    discount += Math.round((subTotal * adhocDiscountPer) / 100);

  const tax = Math.round((subTotal * taxPercent) / 100);

  const discountDetails = checkDiscountOffer(
    subTotal,
    storeOffers,
    cartItems,
    checkItemDiscount
  );
  discount += Math.round(discountDetails.amount);
  const netPayable = subTotal - discount + tax;

  return {
    subTotal: subTotal,
    discount: discount,
    discountPercent: discountDetails.percentage,
    taxAmount: tax,
    netPayable: netPayable,
  };
};

const getDifferenceInCartForKOT = (oldCart, newCart, selectedTableNumber) => {
  let diffCart = { ...oldCart, selectedTableNumber, cartItems: [] };

  // if old cart was empty return new cartitems
  if (!oldCart.cartItems || oldCart.cartItems.length === 0)
    return { ...diffCart, cartItems: newCart.cartItems };

  // if new cart is empty return old cart with negative item counts
  if (newCart.cartItems.length === 0) {
    const clearOrderCartItems = oldCart.cartItems.map((item) => {
      return { ...item, Count: item.Count * -1 };
    });

    return { ...diffCart, cartItems: clearOrderCartItems };
  }

  const newIncomingItems = newCart.cartItems.filter(
    (item) =>
      !oldCart.cartItems.find((x) => x.MenuItemId === item.MenuItemId) &&
      item.Questions.length <= 0
  );

  diffCart.cartItems = diffCart.cartItems.concat(newIncomingItems);

  const newIncomingItemsWithFQ = newCart.cartItems.filter((item) => {
    if (item.Questions.length > 0) {
      const oldItemsWithFQ = oldCart.cartItems.filter(
        (oldItem) => oldItem.MenuItemId === item.MenuItemId
      );

      const oldMatchingItems = oldItemsWithFQ.filter((old) => {
        let match = false;
        old.Answers.forEach((oldAns, index) => {
          if (
            oldAns.answer === item.Answers[index].answer &&
            oldAns.question === item.Answers[index].question
          ) {
            match = true;
          }
        });
        if (match === true) {
          return old;
        }
      });

      if (oldMatchingItems.length <= 0) {
        return item;
      }
    }
  });

  diffCart.cartItems = diffCart.cartItems.concat(newIncomingItemsWithFQ);

  for (let index = 0; index < oldCart.cartItems.length; index++) {
    const item = oldCart.cartItems[index];

    let itemInNewCart;

    if (item.Questions.length > 0) {
      itemInNewCart = newCart.cartItems.find((newItem) => {
        if (newItem.MenuItemId === item.MenuItemId) {
          let match = true;
          newItem.Answers.forEach((ans, index) => {
            if (
              ans.answer !== item.Answers[index].answer &&
              ans.question === item.Answers[index].question
            ) {
              match = false;
            }
          });
          if (match === true) {
            return newItem;
          }
        }
      });
    } else {
      itemInNewCart = newCart.cartItems.find(
        (x) => x.MenuItemId === item.MenuItemId
      );
    }

    if (itemInNewCart) {
      if (item.Count !== itemInNewCart.Count) {
        diffCart.cartItems.push({
          ...item,
          Count: itemInNewCart.Count - item.Count,
        });
      }
    } else {
      console.log("remove item: ", item);
      // item has been removed from new cart
      diffCart.cartItems.push({
        ...item,
        Count: -1, // count is already set to zero due to decrease
      });
    }
  }

  return diffCart;
};

const getOrderType = (store) => {
  const obj = store.StoreOrderDispatchType.find((dt) => {
    return dt.DispatchTypeId === 2; //2 = Takeaway in POS Code
  });

  if (obj == null) {
    let dispatchType = store.StoreOrderDispatchType[0].DispatchTypeId;
    if (dispatchType === 1) {
      return 0;
    } else if (dispatchType === 2) {
      return 1;
    } else if (dispatchType === 4) {
      return 3;
    }
  } else {
    return 1;
  }
};

const searchMenu = (menu, searchString) => {
  let selectedItems = [];
  menu.forEach((category) => {
    let filtered = category.MenuItems.filter((item) => {
      //return item.ItemCode?.includes(searchString);
      return item.ItemCode === searchString;
    });
    if (filtered && filtered.length > 0) {
      selectedItems = selectedItems.concat(filtered);
    }
  });

  return selectedItems;
};

export default reducer;
