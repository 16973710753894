import React, { useEffect } from "react";

import { Paper, Grid, Typography, Badge } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import CancelIcon from "@material-ui/icons/Cancel";
import DineIn from "@material-ui/icons/LocalDining";
import ReceiptIcon from "@material-ui/icons/Receipt";
import TransactionListBox from "../TransactionList/TransactionListBox";
import { getPendingOrdersCount } from "../../api/service";
import bellSound from "../../assets/bell.mp3";
import PendingTransactionsDialog from "../TransactionList/PendingTransactions";

import "./BottomButton.css";

const BottomButtonGroup = (props) => {
  const dispatch = useDispatch();
  const { displayOption, orderType, isSavedOrder, store, token } = useSelector(
    (state) => {
      return {
        displayOption: state.displayOption,
        orderType: state.cartItems.orderType,
        isSavedOrder: state.cartItems.isSavedOrder,
        store: state.store,
        token: state.token,
      };
    }
  );

  const [openTransactionListBox, setOpenTransactionListBox] =
    React.useState(false);
  const [openPendingTransactionsBox, setOpenPendingTransactionsBox] =
    React.useState(false);

  const [pendingTransactionCount, setPendingTransactionCount] =
    React.useState(0);

  const [isLoading, setIsLoading] = React.useState(true);

  const signOut = () => {
    dispatch({ type: "LOGOUT" });
  };

  const backToMenu = () => {
    dispatch({ type: "BACKTOMENU" });
  };

  const showTableList = () => {
    dispatch({ type: "SHOWTABLEPOPUP" });
  };

  const openTransactionsBox = () => {
    if (isLoading == false) {
      if (pendingTransactionCount > 0) {
        setOpenPendingTransactionsBox(!openPendingTransactionsBox);
      } else {
        setOpenTransactionListBox(!openTransactionListBox);
      }
    }
  };

  const closeTransactionListBox = () => {
    setOpenTransactionListBox(false);
  };

  const closePendingTransactionsBox = () => {
    setOpenPendingTransactionsBox(false);
  };

  const allTransactionsAccepted = async () => {
    setOpenPendingTransactionsBox(false);
    setOpenTransactionListBox(true);
    await fetchPendingTransactionCount();
  };

  const fetchPendingTransactionCount = async () => {
    try {
      if (store.StoreId) {
        const OrderCount = await getPendingOrdersCount(store.StoreId, token);
        setPendingTransactionCount(OrderCount);
        setIsLoading(false);
        if (OrderCount > 0) {
          let audio = new Audio(bellSound);
          audio.play();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    fetchPendingTransactionCount();
    const interval = setInterval(() => {
      fetchPendingTransactionCount();
    }, 60000); //1 minute
    return () => clearInterval(interval);
  }, [store.StoreId]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={"auto"}>
          <Badge
            badgeContent={pendingTransactionCount}
            color="secondary"
            max={9999}
          >
            <Paper
              elevation={isLoading ? 0 : 3}
              style={{
                width: 90,
                textAlign: "center",
                padding: 8,
                backgroundColor: isLoading ? "rgba(0, 0, 0, 0.12)" : "",
                color: isLoading ? "rgba(0, 0, 0, 0.26)" : "",
                cursor: "pointer",
              }}
              onClick={openTransactionsBox}
            >
              <ReceiptIcon style={{ fontSize: 25 }} />
              <Typography style={{ fontSize: 12 }}>Order Board</Typography>
            </Paper>
          </Badge>
        </Grid>
        {orderType === 3 && (
          <Grid item xs={"auto"}>
            <Paper
              elevation={3}
              style={{
                width: 80,
                textAlign: "center",
                padding: 8,
                cursor: "pointer",
              }}
              onClick={showTableList}
            >
              <DineIn style={{ fontSize: 25 }} />

              <Typography style={{ fontSize: 12 }}>Tables</Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
      <TransactionListBox
        open={openTransactionListBox}
        handleClose={closeTransactionListBox}
      />
      <PendingTransactionsDialog
        open={openPendingTransactionsBox}
        handleClose={closePendingTransactionsBox}
        onCompleted={allTransactionsAccepted}
      />
    </>
  );
};

export default BottomButtonGroup;
