import React from "react";
import { Grid, Typography } from "@material-ui/core";

const ReceiptFooter = (props) => {
  const { store } = props;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          textAlign: "center",
          width: "100%",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 12 }}>
          {store.ThanksMessageOnReceipt &&
          store.ThanksMessageOnReceipt.length > 0
            ? store.ThanksMessageOnReceipt
            : null}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontSize: 9, textAlign: "center" }}>
          Powered by Eatachi
        </Typography>
        <Typography style={{ fontSize: 9, textAlign: "center" }}>
          www.eatachi.co
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReceiptFooter;
