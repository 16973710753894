import React, { useEffect } from "react";
import { Paper, Typography, Grid, IconButton } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../assets/Logo.jpg";
import AddQtyAndDisscount from "../AddQtyAndDisscount/AddQtyAndDisscount";
import PersonIcon from "@material-ui/icons/Person";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import useEventListener from "../eventHook/EventListnerHook";
import ForcedQuestion from "./forcedQuestionWithId";

import { formatPrice } from "../../helper";

const Cart = (props) => {
  const { number, handleNumber } = props;

  const dispatch = useDispatch();

  const [deleteEvent, setDeleteEvent] = React.useState("");
  const [forcedQuestionClicked, setForcedQuestionClicked] = React.useState("");
  const [forcedQuestion, setForcedQuestion] = React.useState([]);
  const [forcedQuestionId, setForcedQuestionId] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [deliveryFee, setDeliveryFee] = React.useState(0);
  const [selectedId, setSelectedId] = React.useState("randomId");
  const [subTotal, setSubTotal] = React.useState(0.0);
  const [taxAmount, setTaxAmount] = React.useState(0.0);
  const [Total, setTotal] = React.useState(0.0);

  const {
    cart,
    FirstName,
    store,
    adhocDiscountPer,
    discount,
    selectedTableNumber,
  } = useSelector((state) => {
    return {
      cart: state.cartItems,
      FirstName: state.FirstName,
      store: state.store,
      adhocDiscountPer: state.adhocDiscountPer,
      discount: state.discount,
      selectedTableNumber: state.selectedTableId,
    };
  });

  const date = new Date();
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  React.useEffect(() => {
    getSubTotalAmount();
  });

  React.useEffect(() => {
    //console.log("store", store);

    let deliveryLocation = cart.customerInfo.DeliveryArea
      ? cart.customerInfo.DeliveryArea
      : "";

    if (cart.orderType === 0) {
      let charges = store.StoreDeliveryFees.filter(
        (data) => data.LocationName === deliveryLocation
      );

      if (charges.length > 0) {
        if (charges[0].DeliveryFees <= 0) {
          setDeliveryFee(store.DeliveryCharges);
        } else {
          setDeliveryFee(charges[0].DeliveryFees);
        }
      }
    }
  });

  const getSubTotalAmount = () => {
    let amount = 0.0;
    cart.cartItems.map((x, i) => {
      amount = amount + x.ItemTotal;
      if (x.selected === true) setSelectedId(x.Name + i);
      if (x.Answers.length > 0) {
        x.Answers.map((y) => {
          // multiple the answer price with quantity of the item
          if (y.Price) amount = amount + Number(y.Price) * x.Count;
        });
      }
    });

    let tax = Math.round((amount * store.TaxPercent) / 100);

    setSubTotal(amount);
    setTaxAmount(tax);
    setTotal(amount);

    return null;
  };

  const getNumber = (n) => {
    if (number === 0 || number === "") {
      handleNumber(n);
    } else if (n === "remove") {
      if (String(number).length > 0) {
        handleNumber(String(number).slice(0, -1));
      }
    } else if (n === "") {
      handleNumber("");
    } else {
      handleNumber(number + n);
    }
  };

  const getNumberFromEvent = (n) => {
    handleNumber(n);
  };

  const checkQTYorDisscount = (n, item, index) => {
    if (String(n).includes("%")) {
      item.adhocDiscountPer = Math.abs(Number(String(n.split("%")[0])));
      dispatch({
        type: "CARTITEMDISCOUNT",
        payload: { item, index },
      });
    } else {
      if (number > 0 || Number(String(n)) > 0 || String(n) !== "") {
        let newDiscount = discount;

        item.Count = Number(n);
        item.ItemTotal = item.Price * item.Count;

        if (item.adhocDiscountPer > 0) {
          newDiscount = discount - item.disc;
          item.disc = (item.Price / 100) * item.adhocDiscountPer * item.Count;
          item.Answers.map((a) => {
            if (a.Price) {
              item.disc = item.disc + (a.Price / 100) * item.adhocDiscountPer;
            }
          });
          newDiscount = newDiscount + item.disc;
        }
        dispatch({
          type: "CARTITEMQTY",
          payload: { item, index, newDiscount },
        });
      }
    }
    handleNumber("");
  };

  const addItemDiscount = (item, qty = 1) => {
    if (item.OfferAmount > 0) {
      dispatch({
        type: "ITEMDISCOUNT",
        payload: (discount + item.OfferAmount) * qty,
      });
      // setDiscount(discount + item.OfferAmount)
    } else if (item.OfferPercent > 0) {
      dispatch({
        type: "ITEMDISCOUNT",
        payload:
          (discount + Math.round((item.Price / 100) * item.OfferPercent)) * qty,
      });
      // setDiscount(discount + Math.round((item.Price / 100) * item.OfferPercent))
    } else if (item.adhocDiscountPer > 0) {
      let disc = (item.Price / 100) * item.adhocDiscountPer + discount;
      item.Answers.map((a) => {
        if (a.Price) {
          disc = disc + (a.Price / 100) * item.adhocDiscountPer;
        }
      });
      dispatch({
        type: "ITEMDISCOUNT",
        payload: disc,
      });
    }
  };

  const removeItemDiscount = (item, qty = 1) => {
    if (item.OfferAmount > 0) {
      dispatch({
        type: "ITEMDISCOUNT",
        payload: (discount - item.OfferAmount) * qty,
      });
      // setDiscount(discount + item.OfferAmount)
    } else if (item.OfferPercent > 0) {
      dispatch({
        type: "ITEMDISCOUNT",
        payload:
          (discount - Math.round((item.Price / 100) * item.OfferPercent)) * qty,
      });
      // setDiscount(discount + Math.round((item.Price / 100) * item.OfferPercent))
    } else if (item.adhocDiscountPer > 0) {
      let disc = (item.Price / 100) * item.adhocDiscountPer;
      item.Answers.map((a) => {
        if (a.Price) {
          disc = disc + (a.Price / 100) * item.adhocDiscountPer;
        }
      });
      disc = discount - disc;
      if (discount < 0) {
        dispatch({
          type: "ITEMDISCOUNT",
          payload: 0,
        });
      } else {
        dispatch({
          type: "ITEMDISCOUNT",
          payload: disc,
        });
      }
    }
  };

  const selectItems = (i) => {
    cart.cartItems.map((data) => {
      data.selected = false;
      return data;
    });
    cart.cartItems[i].selected = true;
    setSelectedIndex(i);
    checkQTYorDisscount(number, cart.cartItems[i], i);
  };

  const increaseCount = (i) => {
    addItemDiscount(cart.cartItems[i]);
    cart.cartItems[i].Count++;
    cart.cartItems[i].ItemTotal =
      cart.cartItems[i].Price * cart.cartItems[i].Count;

    if (cart.cartItems[i].adhocDiscountPer > 0) {
      cart.cartItems[i].disc =
        (cart.cartItems[i].Price / 100) *
        cart.cartItems[i].adhocDiscountPer *
        cart.cartItems[i].Count;

      cart.cartItems[i].Answers.map((a) => {
        if (a.Price) {
          cart.cartItems[i].disc =
            cart.cartItems[i].disc +
            (a.Price / 100) *
              cart.cartItems[i].adhocDiscountPer *
              cart.cartItems[i].Count;
        }
      });
    }
    dispatch({ type: "INCREASECOUNT", payload: cart });
  };

  const decreaseCount = (i) => {
    if (cart.cartItems[i].Count === 0) return;

    removeItemDiscount(cart.cartItems[i]);
    cart.cartItems[i].Count--;
    cart.cartItems[i].ItemTotal =
      cart.cartItems[i].Price * cart.cartItems[i].Count;

    if (cart.cartItems[i].adhocDiscountPer > 0) {
      cart.cartItems[i].disc =
        (cart.cartItems[i].Price / 100) *
        cart.cartItems[i].adhocDiscountPer *
        cart.cartItems[i].Count;

      cart.cartItems[i].Answers.map((a) => {
        if (a.Price) {
          cart.cartItems[i].disc =
            cart.cartItems[i].disc +
            (a.Price / 100) *
              cart.cartItems[i].adhocDiscountPer *
              cart.cartItems[i].Count;
        }
      });
    }
    dispatch({ type: "DECREASECOUNT", payload: cart });
  };

  const scrollToItem = () => {
    if (cart.cartItems.length > 0) {
      document
        .getElementById(selectedId)
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  useEffect(scrollToItem, [selectedId]);

  const TotalAmount =
    cart.orderType === 0
      ? deliveryFee +
        Total -
        ((Total / 100) * (adhocDiscountPer ?? 0) + (discount ?? 0)) +
        taxAmount
      : Total -
        ((Total / 100) * (adhocDiscountPer ?? 0) + (discount ?? 0)) +
        taxAmount;

  const handleCrtlDelEvent = React.useCallback((key) => {
    if (key.key === "Control") {
      setDeleteEvent(key.key);
    } else if (key.key === "Delete") {
      if (deleteEvent === "Control") {
        dispatch({ type: "REMOVEITEMFROMCART", payload: selectedIndex });
        setSelectedIndex(0);
      }
    }
  });

  useEventListener("keydown", handleCrtlDelEvent);

  const handelForcedQuestionOpen = (id, data) => {
    setForcedQuestion(data);
    setForcedQuestionId(id);
    setForcedQuestionClicked(true);
  };

  const handleUpdateToCart = (data) => {
    let cart = cart;
    cart.cartItems[forcedQuestionId] = data;

    dispatch({ type: "UPDATETOCART", payload: cart });
    setForcedQuestionId(0);
    setForcedQuestionClicked(false);
  };

  const handleClose = () => {
    setForcedQuestion([]);
    setForcedQuestionId(0);
    setForcedQuestionClicked(false);
  };

  const getItemTotalPrice = (item) => {
    let total = item.ItemTotal;
    item.Answers.map((a, i) => {
      if (a.Price) {
        total += item.Count * parseFloat(a.Price);
      }
    });

    return total;
  };

  const getItemSinglePrice = (item) => {
    let total = item.Price;
    item.Answers.map((a, i) => {
      if (a.Price) {
        total += parseFloat(a.Price);
      }
    });

    return total;
  };

  return (
    <div>
      <Grid
        container
        justifyContent="space-evenly"
        alignContent="center"
        alignItems="center"
        style={{ marginTop: "16px", backgroundColor: "white" }}
      >
        <Grid item xs={4}>
          <Paper style={{ background: "black", color: "white" }} id="start">
            <Typography>{`${
              date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
            }-${month[date.getMonth()]}-${date.getFullYear()}`}</Typography>
            <Typography>{`#01 - ${formatAMPM(date)}`}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={7} style={{ textAlign: "right" }}>
          <img src={logo} alt="" style={{ width: "50%", alignSelf: "right" }} />
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 10, marginBottom: 10 }}>
            <Grid container>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    style={{
                      fontSize: cart.orderType === 0 ? 14 : 16,
                      fontWeight: "bold",
                    }}
                  >
                    Receipt: {`${cart.TransID}`}
                  </Typography>
                  {cart.isSavedOrder && (
                    <Typography
                      style={{
                        fontSize: cart.orderType === 0 ? 14 : 16,
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      &nbsp;[SAVED]
                    </Typography>
                  )}
                </div>
                <Typography
                  style={{
                    fontSize: cart.orderType === 0 ? 12 : 13,
                    fontWeight: "bold",
                  }}
                >
                  {`${
                    date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
                  }-${month[date.getMonth()]}-${date.getFullYear()}`}
                </Typography>
                <Grid container>
                  <Grid item xs={2}>
                    <PersonIcon style={{ fontSize: 35 }} />
                  </Grid>
                  <Grid item xs={10}>
                    {cart.orderType === 0 && (
                      <>
                        <Typography style={{ fontSize: 12 }}>
                          Name : {cart.customerInfo.CustomerName}
                        </Typography>
                        <Typography style={{ fontSize: 12 }}>
                          Phone : {cart.customerInfo.PhoneNumber}
                        </Typography>
                        <Typography style={{ fontSize: 12 }}>
                          Address:{" "}
                          {cart.customerInfo.length > 0 &&
                          cart.customerInfo.StreetAddress.length > 25
                            ? cart.customerInfo.StreetAddress.substring(
                                0,
                                25 - 3
                              ) + "..."
                            : cart.customerInfo.StreetAddress}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Typography style={{ fontSize: 12 }}>
                  User : {FirstName}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "right" }}>
                <Typography variant="h5">
                  {store.Currency + " " + formatPrice(TotalAmount)}
                </Typography>
                <Typography>Gross {formatPrice(subTotal)}</Typography>
                <Typography>
                  Discount{" "}
                  {formatPrice((subTotal / 100) * adhocDiscountPer + discount)}
                </Typography>
                {cart.orderType === 0 && (
                  <Typography>
                    Delivery Fee {formatPrice(deliveryFee)}
                  </Typography>
                )}
                {taxAmount > 0 && (
                  <Typography>Tax {formatPrice(taxAmount)}</Typography>
                )}
              </Grid>
              {cart.orderType === 3 && selectedTableNumber > -1 && (
                <Grid item xs={12}>
                  <Typography style={{ fontSize: 12 }}>
                    Table # : {selectedTableNumber}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Paper
        style={{
          height: "36vh",
          overflowY: "scroll",
          overflowX: "hidden",
          padding: 6,
          marginBottom: 3,
        }}
        id="randomId"
      >
        {!cart
          ? null
          : cart.cartItems.length > 0
          ? cart.cartItems.map((item, i) => (
              <div key={i}>
                <Paper
                  id={item.Name + i}
                  onClick={() => selectItems(i)}
                  style={{
                    background: item.selected ? "rgba(224, 224, 224, 1)" : null,
                    padding: 3,
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={1}>
                      {!item.ImagePath ? (
                        <div style={{ height: 10 }}></div>
                      ) : (
                        <img
                          src={item.ImagePath}
                          alt=""
                          style={{ height: 10 }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography style={{ fontSize: 12 }}>
                        {item.Name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => decreaseCount(i)}
                          color="secondary"
                        >
                          <RemoveCircleOutlineIcon
                            style={{ fontSize: "1.8rem" }}
                          />
                        </IconButton>
                        <Typography style={{ fontSize: 12 }}>
                          {item.Count}
                        </Typography>
                        <IconButton
                          onClick={() => increaseCount(i)}
                          color="primary"
                        >
                          <AddCircleOutlineIcon
                            style={{ fontSize: "1.8rem" }}
                          />
                        </IconButton>
                        <Typography style={{ fontSize: 12 }}>
                          x {formatPrice(getItemSinglePrice(item))}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      <Typography style={{ fontSize: 12 }}>
                        {store.Currency +
                          " " +
                          formatPrice(getItemTotalPrice(item))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
                {item.Answers && item.Answers.length > 0 && (
                  <Paper
                    key={"key-answers-" + i}
                    style={{
                      marginLeft: 40,
                      marginTop: 3,
                      background: item.selected
                        ? "rgba(224, 224, 224, 1)"
                        : null,
                      color: "#f05a28",
                      padding: 3,
                    }}
                    onClick={() => handelForcedQuestionOpen(i, item)}
                  >
                    {item.Answers.map((a, i) => {
                      return (
                        <Grid
                          container
                          alignContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={8}>
                            <Typography style={{ fontSize: 11 }}>
                              {a.answer}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Paper>
                )}
              </div>
            ))
          : null}
      </Paper>

      <AddQtyAndDisscount
        getnumber={getNumber}
        num={number}
        getNumberFromEvent={getNumberFromEvent}
        Total={TotalAmount}
        handleNumber={handleNumber}
      />

      {forcedQuestionClicked && (
        <>
          <ForcedQuestion
            itemToAdd={forcedQuestion}
            onAddToCart={handleUpdateToCart}
            onHandleClose={handleClose}
          />
        </>
      )}
    </div>
  );
};

export default Cart;
