import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";

import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from '../Drawer/Drawer'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Header = () => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState()
    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    }
  return (
    <>
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <MenuIcon  />
        </IconButton>
      </Toolbar>
    </AppBar>
    <Drawer toggle={toggleDrawer} open={isOpen}/>
    </>
  );
};

export default Header;
