import React, { useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  Chip,
  CircularProgress,
} from "@material-ui/core";

import moment from "moment";
import Constants from "../../utils/Constants";
import {
  Home,
  Person,
  WatchLater,
  HourglassFull,
  Update,
  DirectionsCar,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import TransactionDetailsDialog from "./TransactionDetails";
import { formatPrice } from "../../helper";

const useStyles = makeStyles((theme) => ({
  gridIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  hourglassIcon: {
    display: "flex",
    flexWrap: "wrap",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    maxWidth: "232px",
    width: "100%",
  },
  cardBody: {
    cursor: "pointer",
    fontSize: ".85rem",
  },
  header: {
    justifyContent: "space-between",
  },
}));

export default function OrderCard(props) {
  const classes = useStyles();

  const { order, statusUpdate } = props;

  const [openTransDetails, setOpenTransDetails] = React.useState(false);
  const [isUpdatingAccept, setIsUpdatingAccept] = React.useState(false);
  const [isUpdatingReject, setIsUpdatingReject] = React.useState(false);

  const closeDetails = () => {
    setOpenTransDetails(false);
  };

  useEffect(() => {
    setIsUpdatingAccept(false);
    setIsUpdatingReject(false);
  }, [order]);

  const onUpdateStatusClick = (orderId, nextStatus) => {
    if (nextStatus == Constants.OrderStatus.Accepted) {
      setIsUpdatingAccept(true);
    } else if (nextStatus == Constants.OrderStatus.Rejected) {
      setIsUpdatingReject(true);
    }
    statusUpdate(orderId, nextStatus);
  };

  const GetTicketSourceTypeTag = (Source, Type) => {
    if (
      Source == Constants.OrderSource.Online &&
      Type == Constants.OrderType.Delivery
    )
      return "ODEL";
    else if (
      Source == Constants.OrderSource.Online &&
      Type == Constants.OrderType.Pickup
    )
      return "PKUP";
    else if (
      Source == Constants.OrderSource.Online &&
      Type == Constants.OrderType.DriveThrough
    )
      return "DTHR";
    else if (
      Source == Constants.OrderSource.POS &&
      Type == Constants.OrderType.Delivery
    )
      return "PDEL";
    else if (
      Source == Constants.OrderSource.POS &&
      Type == Constants.OrderType.Pickup
    )
      return "TKWY";
    else if (
      Source == Constants.OrderSource.POS &&
      Type == Constants.OrderType.DineIn
    )
      return "PDIN";
    else return "";
  };

  const GetDateInfo = (OrderDate, DateStoreFormat) => {
    if (moment(OrderDate).isSame(moment(new Date()), "day")) {
      return "Today";
    } else if (
      moment(OrderDate).isSame(
        moment(new Date().setDate(new Date().getDate() - 1)),
        "day"
      )
    ) {
      return "Yesterday";
    }

    return moment(OrderDate).format("DD-MMM-yyyy");
  };

  const getTimeInfo = (orderDate) => {
    return moment(orderDate).format("hh:mm A");
  };

  const getOrderElapsedTime = (OrderDate) => {
    return moment(new Date()).diff(OrderDate, "minutes") + " mins";
  };

  return (
    <>
      <Card
        variant={"outlined"}
        sx={{ maxWidth: 232 }}
        className={classes.card}
      >
        {order && (
          <CardContent>
            <Grid container className={classes.header}>
              <Grid item className={classes.hourglassIcon}>
                <HourglassFull />
                {getOrderElapsedTime(order.Date)}
              </Grid>
              <Chip
                label={GetTicketSourceTypeTag(order.OrderSource, order.Type)}
                color="secondary"
              />
              {order.IsFuture && <Chip label="F" color="secondary" />}
              <Chip label={order.OrderNumber} color="secondary" />
            </Grid>
            <Divider className={classes.divider} />
            <Grid
              item
              onClick={() => setOpenTransDetails(true)}
              className={classes.cardBody}
            >
              <Typography variant="h6">
                {order.DeliveryAddress?.PhoneNumber}
              </Typography>
              <Grid item className={classes.gridIcon}>
                <Person /> {order.DeliveryAddress?.CustomerName}
              </Grid>
              {order.Type == Constants.OrderType.Delivery && (
                <Grid item className={classes.gridIcon}>
                  <Home /> {order.DeliveryAddress?.StreetAddress}
                </Grid>
              )}
              {order.Type == Constants.OrderType.DriveThrough && (
                <Grid item className={classes.gridIcon}>
                  <DirectionsCar />
                  {order.Remarks}
                </Grid>
              )}
              {!order.IsFuture && (
                <Grid item className={classes.gridIcon}>
                  <WatchLater /> {getTimeInfo(order.Date)} -
                  <Typography
                    style={{ fontStyle: "italic", fontSize: "13.6px" }}
                  >
                    {GetDateInfo(order.Date)}
                  </Typography>
                </Grid>
              )}
              {order.IsFuture && (
                <Grid item className={classes.gridIcon}>
                  <WatchLater /> {getTimeInfo(order.FutureDate)} -{" "}
                  <Typography
                    style={{ fontStyle: "italic", fontSize: "13.6px" }}
                  >
                    {" "}
                    {GetDateInfo(order.FutureDate)}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid>
              <Divider className={classes.divider} />
            </Grid>
            <Grid container style={{ justifyContent: "space-between" }}>
              <Grid container xs={12} sm={6} md={6} lg={6} spacing={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingAccept}
                    onClick={() =>
                      onUpdateStatusClick(
                        order.OrderId,
                        Constants.OrderStatus.Accepted
                      )
                    }
                  >
                    {isUpdatingAccept ? <CircularProgress size="1rem" /> : ""}
                    Accept
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isUpdatingReject}
                    onClick={() =>
                      onUpdateStatusClick(
                        order.OrderId,
                        Constants.OrderStatus.Rejected
                      )
                    }
                  >
                    {isUpdatingReject ? <CircularProgress size="1rem" /> : ""}
                    Reject
                  </Button>
                </Grid>
              </Grid>
              <Grid container xs={12} sm={6} md={6} lg={6} spacing={1}>
                <Grid container style={{ justifyContent: "flex-end" }}>
                  <Grid
                    item
                    style={{ fontSize: "12px", verticalAlign: "super" }}
                  >
                    {order.Currency}
                  </Grid>
                  <Grid item style={{ fontWeight: "bold" }}>
                    {formatPrice(order.NetPayable)}
                  </Grid>
                </Grid>
                <Grid container direction="column" style={{ textAlign: "end" }}>
                  <Typography style={{ fontSize: "10px" }}>
                    {order.TransactionNumber}
                  </Typography>
                  <Typography item style={{ fontSize: "10px" }}>
                    {order.OrderNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
      <TransactionDetailsDialog
        open={openTransDetails}
        handleCancel={closeDetails}
        orderId={order?.OrderId}
      />
    </>
  );
}
