import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  withStyles,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  Chip,
  Box,
  FormControl,
  Backdrop,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch,
  Collapse,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";

import {
  getLiveOrders,
  updateOrderStatus,
  cancelOrder,
  getOrderDetails,
} from "../../api/service";
import TransactionDataTable from "./TransactionDataTable";
import TransactionDetailsDialog from "./TransactionDetails";
import CancelTransactionDialog from "./CancelTransactionDialog";
import PrintReceipt from "../FinishTransaction/PrintReceipt";
import Constants from "../../utils/Constants";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const CustDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const OrderSource = [
  { id: -1, name: "Show All" },
  { id: 0, name: "Point of Sale" },
  { id: 1, name: "Online" },
];
const OrderType = [
  { id: -1, name: "Show All" },
  { id: 2, name: "Take away" },
  { id: 1, name: "Pickup" },
  { id: 0, name: "Delivery" },
];
const OrderStatus = [
  { id: -1, name: "Show All" },
  { id: 1, name: "Received" },
  { id: 2, name: "Accepted" },
  // { id: 3, name: "Rejected" },
  { id: 6, name: "Dispatched" },
  { id: 7, name: "Delivered" },
  { id: 10, name: "Cancelled" },
];

const TransactionListBox = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose } = props;
  const { store, token } = useSelector((state) => {
    return {
      store: state.store,
      token: state.token,
    };
  });

  const [row, setRows] = React.useState([]);
  const [openTransDetails, setOpenTransDetails] = React.useState(false);
  const [selectedOrderId, setSelectedOrderId] = React.useState(-1);
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [selectedOrderSource, setSelectedOrderSource] = React.useState([]);
  const [selectedOrderType, setSelectedOrderType] = React.useState([]);
  const [selectedOrderStatus, setSelectedOrderStatus] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showFutureOrders, setShowFutureOrders] = React.useState(false);
  const [futureOrdersCount, setFutureOrdersCount] = React.useState(0);
  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
  const [printReceiptOpen, setprintReceiptOpen] = React.useState(false);
  const [orderDetails, setOrderDetails] = React.useState(null);
  const [enterPress, setEnterPress] = React.useState(false);

  const { getnumber, num, getNumberFromEvent, Total, handleNumber } = props;

  const handleEnterPress = () => setEnterPress(false);

  const fetchData = async () => {
    setIsLoading(true);
    const OrderList = await getLiveOrders(
      store.StoreId,
      selectedOrderSource,
      selectedOrderType,
      selectedOrderStatus,
      token
    );

    //removing Rejected and Under Process Orders
    var filteredList = OrderList?.filter((order) => {
      if (
        order.Status != Constants.OrderStatus.Rejected &&
        order.Status != Constants.OrderStatus.UnderProcess
      )
        return order;
    });

    var cancelFiltered = OrderList?.filter((order) => {
      if (
        order.Status != Constants.OrderStatus.Rejected &&
        order.Status != Constants.OrderStatus.UnderProcess &&
        order.Status != Constants.OrderStatus.Canceled
      )
        return order;
    });
    var originalListCount = filteredList.length;

    if (!showFutureOrders) {
      filteredList = filteredList?.filter((order) => {
        if (
          !(
            order.IsFuture &&
            order.Status != Constants.OrderStatus.Received &&
            moment(order.FutureDate).isAfter(new Date(), "day")
          ) ||
          (order.IsFuture && order.Status == Constants.OrderStatus.Canceled)
        ) {
          return order;
        }
      });

      var newLength = filteredList.length;
      setFutureOrdersCount(originalListCount - newLength);
    }

    setRows(filteredList);
    setIsLoading(false);
  };

  useEffect(() => {
    if (open) {
      setShowSuccessAlert(false);
      fetchData();
    }
  }, [
    open,
    selectedOrderSource,
    selectedOrderStatus,
    selectedOrderType,
    showFutureOrders,
  ]);

  const closeDetails = () => {
    setOpenTransDetails(false);
  };

  const onOpenOrderDetails = async (orderId) => {
    setSelectedOrderId(orderId);
    setOpenTransDetails(true);
  };

  const onOpenCancelOrder = async (orderId) => {
    setSelectedOrderId(orderId);
    setOpenCancelDialog(true);
  };

  const onPrintOrderClick = async (orderId) => {
    const order = await getOrderDetails(orderId, token);
    setOrderDetails(order);
    setprintReceiptOpen(true);
  };

  const handleChangeOrderSource = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOrderSource(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeOrderType = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOrderType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeOrderStatus = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOrderStatus(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onStatusUpdateClick = async (orderId, currentStatus) => {
    const newStatus = await updateOrderStatus(orderId, currentStatus);
    fetchData();
  };

  const onCancelOrderClick = async (orderId, reason) => {
    const newStatus = await cancelOrder(orderId, reason);
    setOpenCancelDialog(false);
    setShowSuccessAlert(true);
    fetchData();
  };

  const onSwitchShowFutureOrdersChanged = (event) => {
    setShowFutureOrders(event.target.checked);
  };

  const whenPrintingIsDone = () => {
    setprintReceiptOpen(false);
    // setPrintDineInKOT(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen
        maxWidth="lg"
        style={{ height: "100vh" }}
      >
        <CustDialogTitle id="alert-dialog-title" onClose={handleClose}>
          LIVE Order Board
        </CustDialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            {isLoading ? (
              <>
                <Backdrop style={{ color: "#fff", zIndex: 2 }} open={true}>
                  <CircularProgress
                    color="secondary"
                    style={{ alignSelf: "center" }}
                  />
                </Backdrop>
              </>
            ) : (
              <>
                <Grid item xs={4}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel>Order Source</InputLabel>
                    <Select
                      id="order-source"
                      multiple
                      fullWidth
                      value={selectedOrderSource}
                      onChange={handleChangeOrderSource}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                OrderSource.find((item) => {
                                  return item.id == value;
                                }).name
                              }
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {OrderSource.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel>Order Type</InputLabel>
                    <Select
                      multiple
                      fullWidth
                      value={selectedOrderType}
                      onChange={handleChangeOrderType}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                OrderType.find((item) => {
                                  return item.id == value;
                                }).name
                              }
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {OrderType.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel>Order Status</InputLabel>
                    <Select
                      multiple
                      fullWidth
                      value={selectedOrderStatus}
                      onChange={handleChangeOrderStatus}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                OrderStatus.find((item) => {
                                  return item.id == value;
                                }).name
                              }
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {OrderStatus.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl style={{ width: "100%" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={onSwitchShowFutureOrdersChanged}
                            checked={showFutureOrders}
                            // style={{ color: "green" }}
                          />
                        }
                        label={"Show Future (" + futureOrdersCount + ")"}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Collapse in={showSuccessAlert}>
                    <Alert
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setShowSuccessAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      Order has been cancelled.
                    </Alert>
                  </Collapse>
                </Grid>
                <Grid container xs={12}>
                  <TransactionDataTable
                    rows={row}
                    onSelectOrder={onOpenOrderDetails}
                    onUpdateStatus={onStatusUpdateClick}
                    onCancelOrder={onOpenCancelOrder}
                    onPrintOrder={onPrintOrderClick}
                    printOpen={printReceiptOpen}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <TransactionDetailsDialog
        open={openTransDetails}
        handleCancel={closeDetails}
        handlePrint={onPrintOrderClick}
        orderId={selectedOrderId}
        printOpen={printReceiptOpen}
      />
      <CancelTransactionDialog
        open={openCancelDialog}
        orderId={selectedOrderId}
        handleClose={() => setOpenCancelDialog(false)}
        onCancelOrder={onCancelOrderClick}
      ></CancelTransactionDialog>
      <PrintReceipt
        order={orderDetails}
        print={printReceiptOpen}
        printingDone={whenPrintingIsDone}
        // number={num}
        // handleNumber={handleNumber}
        // handleEnterPress={handleEnterPress}
      />
    </>
  );
};

export default TransactionListBox;
