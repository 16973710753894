import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import moment from "moment";

import { formatPrice } from "../../helper";
import ReceiptFooter from "./receiptFooter";
import ReceiptHeader from "./receiptHeader";
import ReceiptCustomerInfo from "./receiptCustomerInfo";

const useStyles = makeStyles((theme) => ({
  CashReceipt: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  DateTime: {
    textAlign: "left",
    fontSize: 12,
    fontWeight: "bold",
  },
  GridDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  GridDivTyopGraphy: {
    textAlign: "right",
    fontSize: 12,
    flexBasis: "20%",
    flexGrow: 0,
  },
  GridDivItemTypoGraphy: {
    textAlign: "left",
    fontSize: 12,
    flexBasis: "50%",
    flexGrow: 0,
  },
  GridDivQtyTypoGraphy: {
    textAlign: "right",
    fontSize: 12,
    flexBasis: "10%",
    flexGrow: 0,
  },
}));

const ReceiptFormat2 = (props) => {
  const classes = useStyles();

  const {
    store,
    cart,
    FirstName,
    discount,
    adhocDiscountPer,
    selectedTableNumber,
    total,
    taxAmount,
    deliveryFee,
    TotalAmount,
    orderType,
    date,
    month,
    number,
    quantity,
    revenueAuthorityID,
  } = props;

  const getItemTotalPrice = (item) => {
    let total = item.ItemTotal;
    item.Answers.map((a, i) => {
      if (a.Price) {
        total += item.Count * parseFloat(a.Price);
      }
    });

    return total;
  };

  const getItemSinglePrice = (item) => {
    let total = item.Price;
    item.Answers.map((a, i) => {
      if (a.Price) {
        total += parseFloat(a.Price);
      }
    });

    return total;
  };

  return (
    <Grid container style={{ fontSize: 12, maxWidth: 250 }}>
      <ReceiptHeader store={store} authorityID={revenueAuthorityID} />

      {props.isPrePaymentInvoice === true && (
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "black", maxWidth: "100%" }}
        >
          <Typography className={classes.CashReceipt}>
            Pre Payment Invoice
          </Typography>
        </Grid>
      )}

      {/* cash Receipt */}
      {(!props.isPrePaymentInvoice || props.isPrePaymentInvoice === false) && (
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "black", maxWidth: "100%" }}
        >
          <Typography className={classes.CashReceipt}>Cash Receipt</Typography>
        </Grid>
      )}
      {/* DateTime */}
      <Grid item xs={6}>
        <Typography className={classes.DateTime}>Print DateTime:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          className={classes.DateTime}
          style={{ fontWeight: "normal" }}
        >
          {moment().format("DD-MMM-yyyy hh:mm A")}
        </Typography>
      </Grid>

      {/* Bill no and orderType */}
      <Grid item xs={6}>
        <Typography className={classes.DateTime}>Bill#</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          className={classes.DateTime}
          style={{ fontWeight: "normal" }}
        >
          {cart.TransID}
        </Typography>
      </Grid>
      {revenueAuthorityID && revenueAuthorityID !== "" && (
        <>
          <Grid item xs={6}>
            <Typography className={classes.DateTime}>
              SRB Invoice ID:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className={classes.DateTime}
              style={{
                fontWeight: "normal",
              }}
            >
              {revenueAuthorityID}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <Typography className={classes.DateTime}>Order Type:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          className={classes.DateTime}
          style={{
            fontWeight: "normal",
          }}
        >
          {orderType[Number(cart.orderType)]?.toUpperCase()}
        </Typography>
      </Grid>
      {/* Table no  */}
      {cart.orderType === 3 && selectedTableNumber > -1 && (
        <Grid item xs={4}>
          <Typography
            className={classes.DateTime}
            style={{
              fontWeight: "normal",
            }}
          >
            Table : - {selectedTableNumber}
          </Typography>
        </Grid>
      )}

      <Grid item xs={6}>
        <Typography
          className={classes.DateTime}
          style={{
            fontWeight: "normal",
          }}
        >
          Waiter:
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          className={classes.DateTime}
          style={{
            fontWeight: "normal",
          }}
        >
          {FirstName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      {cart.orderType === 0 && (
        <>
          <ReceiptCustomerInfo customer={cart.customerInfo} />
          <Grid item xs={12}>
            <hr />
          </Grid>
        </>
      )}
      {/* Headings */}
      <Grid item xs={12}>
        <div className={classes.GridDiv}>
          <Typography
            className={classes.GridDivTyopGraphy}
            style={{
              fontWeight: "Bold",
              textAlign: "left",
              flexBasis: "40%",
            }}
          >
            Item Name
          </Typography>
          <Typography
            className={classes.GridDivTyopGraphy}
            style={{
              fontWeight: "Bold",
            }}
          >
            Qty
          </Typography>
          <Typography
            className={classes.GridDivTyopGraphy}
            style={{
              fontWeight: "Bold",
            }}
          >
            Price
          </Typography>
          <Typography
            className={classes.GridDivTyopGraphy}
            style={{
              fontWeight: "Bold",
            }}
          >
            Total
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>

      {cart.cartItems.map((data, index) => (
        <>
          <Grid item xs={12} key={"item_" + index}>
            <div className={classes.GridDiv}>
              <Typography className={classes.GridDivItemTypoGraphy}>
                {data.Name}
              </Typography>
              <Typography className={classes.GridDivQtyTypoGraphy}>
                {data.Count}
              </Typography>
              <Typography className={classes.GridDivTyopGraphy}>
                {formatPrice(getItemSinglePrice(data))}
              </Typography>
              <Typography className={classes.GridDivTyopGraphy}>
                {formatPrice(getItemTotalPrice(data))}
              </Typography>
            </div>
          </Grid>
          {data.Answers &&
            data.Answers.map((item) => {
              return (
                <Grid container>
                  <Grid item xs={8}>
                    <Typography style={{ fontSize: 11, marginLeft: 20 }}>
                      {item.answer}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
        </>
      ))}
      <Grid item xs={12}>
        <hr />
      </Grid>
      {/* Total Section */}
      <Grid item xs={12}>
        <div className={classes.GridDiv}>
          <Typography
            className={classes.GridDivItemTypoGraphy}
            style={{
              flex: "60%",
              textAlign: "right",
              fontWeight: "Bold",
            }}
          >
            {quantity}
          </Typography>
          <Typography
            className={classes.GridDivTyopGraphy}
            style={{
              fontWeight: "Bold",
              flexBasis: "40%",
            }}
          >
            {formatPrice(total)}
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12}>
        <hr />
      </Grid>
      {/* subtotal */}

      <Grid item xs={6}>
        <Typography
          style={{
            textAlign: "left",
            fontSize: 15,
          }}
        >
          Sub Total:
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          style={{
            textAlign: "right",
            fontSize: 15,
            fontWeight: "Bold",
          }}
        >
          {store.Currency} {formatPrice(total)}
        </Typography>
      </Grid>
      {/* Discount */}

      {adhocDiscountPer !== 0 || discount !== 0 ? (
        <>
          {" "}
          <Grid item xs={6}>
            <Typography
              style={{
                textAlign: "left",
                fontSize: 15,
              }}
            >
              Discount:
            </Typography>
          </Grid>{" "}
          <Grid item xs={6}>
            <Typography
              style={{
                textAlign: "right",
                fontSize: 15,
                fontWeight: "Bold",
              }}
            >
              {store.Currency} -{" "}
              {formatPrice((total / 100) * adhocDiscountPer + discount)}
            </Typography>
          </Grid>
        </>
      ) : null}

      {/* Delivery Charges */}
      {cart.orderType === 0 ? (
        <>
          <Grid item xs={6}>
            <Typography
              style={{
                textAlign: "left",
                fontSize: 15,
              }}
            >
              Delivery Charges:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                textAlign: "right",
                fontSize: 15,
                fontWeight: "Bold",
              }}
            >
              {store.Currency} {formatPrice(deliveryFee)}
            </Typography>
          </Grid>
        </>
      ) : null}

      {taxAmount > 0 && (
        <>
          <Grid item xs={8}>
            <Typography style={{ textAlign: "left", fontSize: 15 }}>
              Tax:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              style={{ textAlign: "right", fontSize: 15, fontWeight: "Bold" }}
            >
              {store.Currency} {formatPrice(taxAmount)}
            </Typography>
          </Grid>
        </>
      )}
      {/* Grand Total */}
      <Grid item xs={6}>
        <Typography
          style={{
            textAlign: "left",
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          Grand Total:
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            textAlign: "right",
            fontWeight: "Bold",
            marginLeft: "5px",
            fontSize: 15,
          }}
        >
          {store.Currency} {formatPrice(TotalAmount)}
        </Typography>
      </Grid>

      <ReceiptFooter store={store} />
    </Grid>
  );
};

export default ReceiptFormat2;
