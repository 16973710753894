import React from 'react';
import {useSelector} from 'react-redux'
import { Route,Redirect } from 'react-router-dom';


const PrivateRoute = ({component:Component,...rest})=>{
    const {isLogedIn} = useSelector(state=>{
        return{
            isLogedIn:state.isLogedIn
        }
    })
    return(
        <Route
            {...rest}
            render={props=>{
                if (isLogedIn){
                    return <Component {...props}/>
                }else{
                    return(
                        <Redirect

                        to={
                            {
                                pathname:'/login',
                                state:{
                                    from:props.location
                                }
                            }
                        }



                        />
                    )
                }
            }}
        
        />
    )

}
export default PrivateRoute;