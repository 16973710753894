export default class Constants {
  static OrderSource = {
    Online: 1,
    POS: 2,
  };
  static OrderType = {
    Delivery: 0,
    Pickup: 1,
    DriveThrough: 2,
    DineIn: 3,
  };
  static OrderStatus = {
    Received: 1,
    Accepted: 2,
    Rejected: 3,
    UnderProcess: 4,
    Ready: 5,
    Dispatched: 6,
    Delivered: 7,
    Canceled: 10,
  };
  static PaymentMode = {
    Cash: 1,
    CreditCard: 2,
  };
}
