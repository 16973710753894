import React from "react";
import { Grid, Typography } from "@material-ui/core";

const ReceiptHeader = (props) => {
  const { store, authorityID } = props;

  return (
    <Grid
      item
      xs={12}
      style={{
        textAlign: "center",
        width: "100%",
        marginBottom: 5,
      }}
    >
      {store.ShowLogoOnReceipt && <img src={store.BrandLogo} width="75" />}
      <Typography style={{ fontWeight: "bold" }}>{store.Name}</Typography>
      <Typography style={{ fontSize: 12 }}>{store.StreetAddress}</Typography>
      {authorityID && store.NTN && (
        <Typography style={{ fontSize: 12 }}>SNTN: {store.NTN}</Typography>
      )}
    </Grid>
  );
};

export default ReceiptHeader;
