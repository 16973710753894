let date = new Date();
let index =
  localStorage.getItem("transactionId") === null
    ? 0
    : JSON.parse(localStorage.getItem("transactionId")).date ===
      `${date.getDate()}/${date.getMonth() + 1}`
    ? JSON.parse(localStorage.getItem("transactionId")).index
    : 0;

function* idMaker() {
  while (true) {
    let update = {
      index: ++index,
      date: `${date.getDate()}/${date.getMonth() + 1}`,
    };
    localStorage.setItem("transactionId", JSON.stringify(update));
    yield update.index;
  }
}

export const transId = idMaker();

export const remove = () => {
  if (index > 1) index--;
  else index = 0;

  let update = {
    index: index,
    date: `${date.getDate()}/${date.getMonth() + 1}`,
  };
  localStorage.setItem("transactionId", JSON.stringify(update));
};
