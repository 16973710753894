import React from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Box,
  Typography,
  Container,
  makeStyles,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Alert from "@material-ui/lab/Alert";
import { loginApi } from "../../api/service";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    WebkitBoxShadow: "0 0 0 1000px white inset",
  },
}));

export default function SignIn() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isLogedIn } = useSelector((state) => {
    return {
      isLogedIn: state.isLogedIn,
      cartItems: state.cartItems,
      userId: state.userId,
    };
  });

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoginCliked, setIsLoginClicked] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  // Hide and Show password
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // *******************

  let isSubscribed = true;

  React.useEffect(() => {
    if (isError) {
      setTimeout(() => {
        if (isSubscribed) {
          setIsError(!isError);
          setIsLoginClicked(!isLoginCliked);
        }
      }, 3000);
    }

    return () => (isSubscribed = false);
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    if (email !== "" && password !== "") {
      setIsLoginClicked(!isLoginCliked);
      let loginData = {
        Username: email,
        Password: password,
      };

      loginApi(loginData)
        .then((res) => {
          dispatch({
            type: "LOGIN",
            payload: {
              token: res.AuthData.TokenInfo.Token,
              UserId: res.AuthData.UserId,
              FirstName: res.AuthData.FirstName,
            },
          });
        })
        .catch((e) => {
          console.log("login error: ", e);
          setIsError(!isError);
        });

      setIsLoginClicked(!isLoginCliked);
    }
  };
  if (isLogedIn) {
    return <Redirect to="/" />;
  }

  const copyright = () => {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://eatachi.co/">
          eatachi.co
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
    );
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.main}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            inputProps={{
              className: classes.textField,
            }}
          />
          <TextField
            sx={{ backgroundColor: "black" }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={values.showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              className: classes.textField,
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoginCliked}
          >
            {isLoginCliked ? <CircularProgress color="secondary" /> : "Sign In"}
          </Button>
        </form>
        {isError ? (
          <Alert severity="error">enter a valid username or password</Alert>
        ) : null}
      </div>
      <Box mt={8}>{copyright()}</Box>
    </Container>
  );
}
