import { validateSubdomain } from "../src/api/service";

export const getStoreInfo = async () => {
  let subdomain = getSubdomain();

  if (subdomain === "") return { StoreId: 0 };

  // console.log("Subdomain: " + subdomain);

  let storeData = await validateSubdomain(subdomain);

  return storeData;
};

export const getSubdomain = () => {
  let hostname = window.location.hostname.toLowerCase();

  hostname = hostname.replace("localhost", "");

  const hostnameSections = hostname.split(".");

  const first = hostnameSections[0];

  return first;
};

export const formatPrice = (x, currency) => {
  switch (currency) {
    case "BRL":
      return x.toFixed(2).replace(".", ",");
    default:
      return new Intl.NumberFormat().format(x); //x.toFixed(2);
  }
};
