import React from "react";
import "./forcedQuestion.css";
import {
  Grid,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { formatPrice } from "../../helper";

const ForcedQuestion = (props) => {
  const { onAddToCart, onHandleClose, itemToAdd } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = (name, price) => {
    console.log(
      itemToAdd.Questions.length === activeStep + 1,
      itemToAdd.Questions.length,
      activeStep,
    );
    if (itemToAdd.Questions.length === activeStep + 1) {
      itemToAdd.Answers[activeStep].Price = price;
      itemToAdd.Answers[activeStep].answer = name;
      onAddToCart(itemToAdd);
      onHandleClose();
      setActiveStep(0);
    } else {
      itemToAdd.Answers[activeStep].Price = price;
      itemToAdd.Answers[activeStep].answer = name;
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };
  console.log(props);
  return (
    <Dialog open={true} fullWidth>
      <DialogTitle id="form-dialog-title">
        Add To Cart
        <IconButton
          aria-label="close"
          className="close_action"
          onClick={onHandleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Stepper activeStep={activeStep} alternativeLabel>
          {itemToAdd.Questions.map((answer, i) => (
            <Step key={answer.ShortName}>
              <StepLabel onClick={() => activeStep >= i && setActiveStep(i)}>
                {answer.ShortName}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <hr />
        <Grid container spacing={2} alignContent="stretch" alignItems="stretch">
          {itemToAdd.Questions[activeStep].QuestionType === 1
            ? itemToAdd.Questions[activeStep].Choices.split(",").map((c, i) => (
                <Grid item xs={6} sm={3} md={2} key={i}>
                  <Paper
                    style={{
                      border: "1px solid gray",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      alignContent: "center",
                      textAlign: "center",
                      cursor:"pointer",
                      
                      background:
                        itemToAdd.Answers[activeStep].answer === c &&
                        "rgb(35, 54, 148)",
                      color:
                        itemToAdd.Answers[activeStep].answer === c && "white",
                    }}
                    onClick={() => handleNext(c, 0)}
                  >
                    <div
                      style={{
                        height: 100,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        
                      }}
                    >
                      <Typography style={{ fontSize: 12, textAlign: "center" }}>
                        {c.trim()}
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
              ))
            : itemToAdd.Questions[activeStep].QuestionType === 2 &&
              JSON.parse(itemToAdd.Questions[activeStep].Choices).map(
                (data, i) => (
                  <Grid item xs={6} sm={3} md={2} key={i}>
                    <Paper
                      style={{
                        border: "1px solid gray",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        alignContent: "center",
                        textAlign: "center",
                        cursor:"pointer",
                        
                        background:
                          itemToAdd.Answers[activeStep].answer ===
                            data.Choice && "rgb(35, 54, 148)",
                        color:
                          itemToAdd.Answers[activeStep].answer ===
                            data.Choice && "white",
                      }}
                      onClick={() => handleNext(data.Choice, data.Price)
                      }
                    >
                      <div
                        style={{
                          height: 100,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          style={{ fontSize: 12, textAlign: "center" }}
                        >
                          {data.Choice.trim()}
                        </Typography>
                        <Typography
                          style={{ fontSize: 12, textAlign: "center" }}
                        >
                          + {itemToAdd.Currency + " " + formatPrice(data.Price)}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                )
              )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className="button-block">
          <div>
            <Button
              onClick={onHandleClose}
              variant="contained"
              color="secondary"
              className="OrderNow"
              style={{ marginRight: 5,cursor:"pointer"}}
            >
              cancel
            </Button>
            <Button
              onClick={handleBack}
              variant="contained"
              color="primary"
              className="OrderNow"
              disabled={activeStep === 0}
            >
              Back
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};
export default ForcedQuestion;
