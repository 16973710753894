import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  labelColumn: {
    textAlign: "Left",
    fontSize: 12,
  },

  valueColumn: {
    textAlign: "right",
    fontSize: 12,
    overflowWrap: "break-word",
  },
}));

const ReceiptCustomerInfo = (props) => {
  const { customer } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Typography className={classes.labelColumn}>Customer Name:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.valueColumn}>
          {customer.CustomerName}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.labelColumn}>Phone Number:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.valueColumn}>
          {customer.PhoneNumber}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.labelColumn}>Email:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.valueColumn}>
          {customer.Email}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.labelColumn}>Address:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.valueColumn}>
          {customer.StreetAddress}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.labelColumn}>Delivery Area:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.valueColumn}>
          {customer.DeliveryArea}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReceiptCustomerInfo;
