import React, { useEffect } from "react";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
} from "@material-ui/core";

export default function CancelTransactionDialog(props) {
  const { open, orderId, handleClose, onCancelOrder } = props;
  const [cancelReason, setCancelReason] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const [cancelInProgress, setCancelInProgress] = React.useState(false);

  const onConfirm = () => {
    if (cancelReason == "") {
      setIsError(true);
      setHelperText("Please enter cancellation reason");
      return;
    } else {
      setCancelInProgress(true);
      setIsError(false);
      setHelperText("");
      onCancelOrder(orderId, cancelReason);
    }
  };

  useEffect(() => {
    if (open) {
      setCancelInProgress(false);
    }
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ height: "100%" }}
    >
      <DialogTitle id="form-dialog-title">Cancel Transaction</DialogTitle>
      <form>
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent="space-around"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12}>
              <TextField
                error={isError}
                margin="dense"
                id="Reason"
                label="Cancellation Reason"
                type="text"
                helperText={helperText}
                fullWidth
                required
                value={cancelReason}
                onKeyDown={(e) => e.stopPropagation()}
                onKeyPress={(e) => e.stopPropagation()}
                onChange={(e) => setCancelReason(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary">
            {cancelInProgress == true ? (
              <CircularProgress color="secondary" size="1.5rem" />
            ) : (
              "Ok"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
