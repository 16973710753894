import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";

import { getOrderDetails } from "../../api/service";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

import "./TransactionList.css";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    justifyContent: "space-between",
  },
  divider: {
    backgroundColor: "black",
    border: "1px solid",
  },
}));

export default function TransactionDetailsDialog(props) {
  const classes = useStyles();

  const { open, handleCancel, handlePrint, printOpen, orderId } = props;

  const [order, setOrder] = useState();

  const { token } = useSelector((state) => {
    return {
      token: state.token,
    };
  });

  const handleClose = () => {
    handleCancel();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (orderId && orderId != -1) {
        const order = await getOrderDetails(orderId, token);
        setOrder(order);
      }
    };
    fetchData();
  }, [orderId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
      style={{ height: "100%" }}
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
        Online Pickup
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Grid item xs={6}>
              <Typography variant="h5">Order Info</Typography>
            </Grid>
            <Grid container className={classes.row}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Ticket Number:
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                }}
              >
                {order?.OrderNumber}
              </Typography>
            </Grid>
            <Grid container className={classes.row}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Transaction Number:
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                }}
              >
                {order?.TransactionNumber}
              </Typography>
            </Grid>
            <Grid container className={classes.row}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Payment Mode:
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                }}
              >
                {order?.PaymentModeDesc}
              </Typography>
            </Grid>
            {order?.SRBInvoiceID && order?.SRBInvoiceID != "" && (
              <Grid container className={classes.row}>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  SRB Invoice ID:
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                  }}
                >
                  {order?.SRBInvoiceID}
                </Typography>
              </Grid>
            )}
            <Grid container className={classes.row}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Status
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                }}
              >
                {order?.StatusDesc}
              </Typography>
            </Grid>
            <Grid container className={classes.row}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Date:
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                }}
              >
                {moment(order?.Date).format("DD-MMM-yyyy hh:mm A")}
              </Typography>
            </Grid>

            {order?.IsFuture && (
              <Grid container className={classes.row}>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Future Date:
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                  }}
                >
                  {moment(order?.FutureDate).format("DD-MMM-yyyy hh:mm A")}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h5">Customer Info</Typography>
            </Grid>
            <Grid container className={classes.row}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Customer Name:
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                }}
              >
                {order?.DeliveryAddress?.CustomerName}
              </Typography>
            </Grid>
            <Grid container className={classes.row}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Phone Number:
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                }}
              >
                {order?.DeliveryAddress?.PhoneNumber}
              </Typography>
            </Grid>
            {order?.Remarks != "" && (
              <>
                <Grid container className={classes.row}>
                  {order?.Type == 2 && (
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Car License Plate #
                    </Typography>
                  )}
                  {order?.Type != 2 && (
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Special Instructions
                    </Typography>
                  )}

                  <Typography
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {order?.Remarks}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <Divider className={classes.divider} />

            <Grid container className={classes.row}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                QTY ITEM
              </Typography>

              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                AMOUNT
              </Typography>
            </Grid>
            <Divider className={classes.divider} />
            {order?.OrderItem.map((item) => {
              return (
                <>
                  <Grid container className={classes.row}>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      {item.Quantity} x {item.MenuItem.Item.Name}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 14,
                        textAlign: "right",
                      }}
                    >
                      {order.Currency} {item.FinalPrice}
                    </Typography>
                  </Grid>
                  {item.Answers.map((answer) => {
                    <>
                      <Grid container className={classes.row}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          {answer.ShortName == ""
                            ? answer.Question
                            : answer.ShortName}{" "}
                          : {answer.Answer}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 14,
                            textAlign: "right",
                          }}
                        >
                          {answer.Price}
                        </Typography>
                      </Grid>
                    </>;
                  })}
                </>
              );
            })}

            <Divider className={classes.divider} />

            <Grid container className={classes.row}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Sub Total
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                  textAlign: "right",
                }}
              >
                {order?.Currency} {order?.Total}
              </Typography>
            </Grid>
            {order?.DeliveryCharge > 0 && (
              <>
                <Grid container className={classes.row}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    Delivery Charges
                  </Typography>

                  <Typography
                    style={{
                      fontSize: 14,
                      textAlign: "right",
                    }}
                  >
                    {order?.Currency} {order?.DeliveryCharge}
                  </Typography>
                </Grid>
              </>
            )}
            {order?.Tax > 0 && (
              <>
                <Grid container className={classes.row}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    Tax
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                      textAlign: "right",
                    }}
                  >
                    {order?.Currency} {order?.Tax}
                  </Typography>
                </Grid>
              </>
            )}
            {order?.DiscountAmount > 0 && (
              <>
                <Grid container className={classes.row}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    Discount Amount
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                      textAlign: "right",
                    }}
                  >
                    {order?.Currency} {order?.DiscountAmount}
                  </Typography>
                </Grid>
              </>
            )}
            {order?.AdhocDiscountAmount > 0 && (
              <>
                <Grid container className={classes.row}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    Adhoc Discount Amount
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                      textAlign: "right",
                    }}
                  >
                    {order?.Currency} {order?.AdhocDiscountAmount}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    Adhoc Discount Percent
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: 14,
                      textAlign: "right",
                    }}
                  >
                    {order?.Currency} {order?.AdhocDiscountPer}
                  </Typography>
                </Grid>
              </>
            )}
            <Divider className={classes.divider} />
            <Grid container className={classes.row}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Payable
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                  textAlign: "right",
                }}
              >
                {order?.NetPayable}
              </Typography>
            </Grid>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => handlePrint(order.OrderId)}
          color="secondary"
        >
          {printOpen && <CircularProgress size="1rem" />}
          Print
        </Button>
        <Button variant="outlined" onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
