import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Box,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import TableIcon from "@material-ui/icons/LocalDining";
import { makeStyles } from "@material-ui/core/styles";
import { formatPrice } from "../../helper";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .PrivateTabIndicator-colorSecondary-14": {
      display: "none",
    },

    "& .Mui-selected": {
      background: "#233694",
      color: "white",
    },
    "& .MuiBadge-badge": {
      right: -35,
      top: 1,
      border: `2px solid black`,
      padding: "0 4px",
      fontSize: "10px !important",
      height: "20px !important",
      minWidth: "20px !important",
      color: "black",
    },
    "& .Mui-selected .MuiBadge-badge": { color: "white", borderColor: "white" },
  },
}));

const TablesPopup = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { open, tableList, currency, Area, cart } = useSelector((state) => {
    return {
      open: state.showSelectTablePopup,
      tableList: state.tables,
      currency: state.store.Currency,
      Area: state.store.Areas,
      cart: state.cartItems,
    };
  });
  const [Seltabs, setSeltabs] = useState(0);

  const close = () => {
    dispatch({ type: "HIDETABLEPOPUP" });
  };

  const selectTable = (tableId) => {
    dispatch({ type: "SELECTTABLE", payload: tableId });
    console.log("tableId", tableId);

    close();
  };

  const onChange = (event, newVal) => {
    setSeltabs(newVal);
  };

  const drawTables = (areaName) => {
    if (tableList)
      return tableList.map((table, index) =>
        table.areaName === areaName ? (
          <Grid item xs="auto" key={"key_grid_item_" + areaName + index}>
            <Paper
              elevation={3}
              style={{
                width: 100,
                height: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => selectTable(table.id)}
            >
              <Typography
                variant="caption"
                style={{ fontSize: 15, fontWeight: "bold" }}
              >
                {table.id}
              </Typography>
              <div
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: "50%",
                  backgroundColor: table.available ? "green" : "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TableIcon
                  style={{
                    fontSize: 20,
                    display: "flex",
                    alignSelf: "center",
                  }}
                />
              </div>
              <Typography variant="caption" style={{ marginTop: 5 }}>
                {table.available
                  ? " - "
                  : currency + " " + formatPrice(table.orderDetail.netPayable)}
              </Typography>
            </Paper>
          </Grid>
        ) : (
          ""
        )
      );
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        key={"ftabpanel_" + index}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const inUseCount = (areaName) => {
    return tableList.filter(
      (d) => d.areaName === areaName && d.orderDetail.cartItems
    ).length;
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="lg" fullWidth>
      <DialogTitle
        disableTypography
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Typography variant="h6">Select Table</Typography>
        <IconButton aria-label="close" onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Tabs value={Seltabs} onChange={onChange} className={classes.tabs}>
          {Area?.map((name, index) => {
            return (
              <Tab
                label={
                  <Badge badgeContent={inUseCount(name.AreaName)}>
                    {name.AreaName}
                  </Badge>
                }
                id={name.Id}
                key={"area_" + index}
              />
            );
          })}
        </Tabs>
        {Area?.map((name, idx) => {
          return (
            <TabPanel value={Seltabs} index={idx} key={"tabpanel_" + idx}>
              <Grid container spacing={4} style={{ marginBottom: 5 }}>
                {drawTables(name.AreaName)}
              </Grid>
            </TabPanel>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

export default TablesPopup;
