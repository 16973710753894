import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Paper,
  Typography,
  Container,
  makeStyles,
} from "@material-ui/core";

import moment from "moment";

import { useReactToPrint, onAfterPrint } from "react-to-print";
import { kotGenerator } from "../../utils/kotNumMaker";

const useStyles = makeStyles((theme) => ({
  printerpaper: {
    color: "black",
    padding: 0,
    margin: 0,
  },

  pageBreak: {
    "@media print": {
      display: "block",
      pageBreakAfter: "always",
      margin: 0,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 0,
      paddingBottom: 0,
      border: 0,
    },
  },
}));

const KOTPrint = (props) => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: (data) => {
      dispatch({ type: "CLEAR_KOT_CART" });
      props.printingDone();
    },
  });

  const { menu, kotCart, FirstName, selectedTableNumber } = useSelector(
    (state) => {
      return {
        menu: state.menu,
        kotCart: state.kotCart,
        FirstName: state.FirstName,
        selectedTableNumber: state.kotCart.selectedTableNumber,
      };
    }
  );

  useEffect(() => {
    if (props.print && kotCart && kotCart.cartItems?.length > 0) {
      handlePrint();
    } else if (props.print) {
      props.printingDone();
    }
  }, [props.print]);

  const getKitchenOrderTickets = () => {
    const itemsGroupByCategory = kotCart.cartItems.reduce(function (r, item) {
      r[item.MenuCategoryId] = r[item.MenuCategoryId] || [];
      r[item.MenuCategoryId].push(item);
      return r;
    }, Object.create(null));

    let KOTs = [];

    for (const [menuCategoryId, items] of Object.entries(
      itemsGroupByCategory
    )) {
      const menuCategory = menu.find(
        (x) => x.MenuCategoryId === menuCategoryId
      );
      const categoryName = menuCategory ? menuCategory.CategoryName : "";

      KOTs.push(
        <Container className={classes.pageBreak} key={"kot-" + KOTs.length}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  fontWeight: "bold",
                }}
              >
                KOT #{" "}
                {kotGenerator.getCrntTicketNumber(parseInt(menuCategoryId))}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ textAlign: "left", fontSize: 11 }}>
                {moment(new Date()).format("DD-MMM-yyyy hh:mm A")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ textAlign: "right", fontSize: 11 }}>
                Employee: {FirstName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ textAlign: "center", fontSize: 11 }}>
                Table #: {selectedTableNumber}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {categoryName}
              </Typography>
            </Grid>
            {items.map((item, index) => {
              return (
                <div key={"key-hr-kot-item-" + index} style={{ width: "100%" }}>
                  <Grid item xs={12}>
                    <Typography style={{ textAlign: "Left", fontSize: 12 }}>
                      {item.Count} x {item.Name}
                    </Typography>
                  </Grid>
                  {item.Answers &&
                    item.Answers.map((a, i) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          key={"key-hr-kot-item-ans-" + index + "-" + i}
                        >
                          <Typography
                            style={{
                              fontSize: 11,
                              textAlign: "left",
                              marginLeft: 20,
                            }}
                          >
                            {a.answer}
                          </Typography>
                        </Grid>
                      );
                    })}
                </div>
              );
            })}
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ textAlign: "left" }}>
                {items.reduce(function (a, item) {
                  return a + item.Count;
                }, 0)}
              </Typography>
              <Typography style={{ textAlign: "center" }}>
                Order #: {kotCart.TransID}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
          </Grid>
        </Container>
      );
    }

    return KOTs;
  };

  return (
    <div style={{ display: "none" }}>
      <Paper className={classes.printerpaper} ref={componentRef}>
        {kotCart && kotCart.cartItems?.length > 0 && getKitchenOrderTickets()}
      </Paper>
    </div>
  );
};

export default KOTPrint;
