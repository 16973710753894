import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
    heigth: 440,
  },
  container: {
    // maxHeight: 440,
    heigth: 440,
  },
});

export default function DataTable(props) {
  const { rows, close } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const { cart } = useSelector((state) => {
    return {
      cart: state.cartItems,
    };
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOnClick = (data, i) => {
    dispatch({ type: "SELECTCUSTOMER", payload: data });
    close(i);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Sr.No</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Street Address</TableCell>
              <TableCell>Delivery Area</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.length > 0 &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.DeliveryAddressId ? row.DeliveryAddressId : i}
                      onClick={() => handleOnClick(row, i)}
                      style={{
                        background:
                          cart.customerInfo.PhoneNumber === row.PhoneNumber &&
                          "rgb(35, 54, 148)",
                        color:
                          cart.customerInfo.PhoneNumber === row.PhoneNumber &&
                          "white",
                      }}
                    >
                      <TableCell>
                        <Typography
                          style={{
                            background:
                              cart.customerInfo.PhoneNumber ===
                                row.PhoneNumber && "rgb(35, 54, 148)",
                            color:
                              cart.customerInfo.PhoneNumber ===
                                row.PhoneNumber && "white",
                          }}
                        >
                          {i + 1}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          style={{
                            background:
                              cart.customerInfo.PhoneNumber ===
                                row.PhoneNumber && "rgb(35, 54, 148)",
                            color:
                              cart.customerInfo.PhoneNumber ===
                                row.PhoneNumber && "white",
                          }}
                        >
                          {row.CustomerName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          style={{
                            background:
                              cart.customerInfo.PhoneNumber ===
                                row.PhoneNumber && "rgb(35, 54, 148)",
                            color:
                              cart.customerInfo.PhoneNumber ===
                                row.PhoneNumber && "white",
                          }}
                        >
                          {row.PhoneNumber}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          style={{
                            background:
                              cart.customerInfo.PhoneNumber ===
                                row.PhoneNumber && "rgb(35, 54, 148)",
                            color:
                              cart.customerInfo.PhoneNumber ===
                                row.PhoneNumber && "white",
                          }}
                        >
                          {row.Email}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          style={{
                            background:
                              cart.customerInfo.PhoneNumber ===
                                row.PhoneNumber && "rgb(35, 54, 148)",
                            color:
                              cart.customerInfo.PhoneNumber ===
                                row.PhoneNumber && "white",
                          }}
                        >
                          {row.StreetAddress}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          style={{
                            background:
                              cart.customerInfo.PhoneNumber ===
                                row.PhoneNumber && "rgb(35, 54, 148)",
                            color:
                              cart.customerInfo.PhoneNumber ===
                                row.PhoneNumber && "white",
                          }}
                        >
                          {row.DeliveryArea}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={rows ? rows.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
